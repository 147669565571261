@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

.pc-only {
    display:block;
    @include mobile {
        display:none;
    }
}
.mo-only {
    display:none;
    @include mobile {
        display:block;
    }
}


// 통합검색
.contents.search {
    //&:after {
    //    content: '';
    //    position: fixed;
    //    top: 0;
    //    bottom: 0;
    //    left: 50%;
    //    z-index: -1;
    //    transform: translateX(-50%);
    //    width: 1380px;
    //    background-color: red;
    //    opacity: .2;
    //}
    .search-tabs-wrap {
        margin-bottom: 48px;
        //border-bottom: 8px solid $color-bg;
        .tabs-wrap {
            max-width: (1380px + 80px);
            margin: 0 auto;
            padding: 0 40px;
            text-align: left;
            @media screen and (min-width:(1380px + 80px)) {
                &:before {
                    right: 40px;
                    left: 40px;
                }
            }
        }
        @include mobile {
            position: relative;
            margin-bottom: 24px;
            &:before,
            &:after {
                position: absolute;
                top: 0;
                bottom: 1px;
                z-index: 1;
                width: 20px;
                content: '';
            }
            &:before {
                left: 0;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
            }
            &:after {
                right: 0;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
            }
            .tabs-wrap {
                max-width: 100%;
                padding:0;
            }
        }

        //& + .search-result-category {
        //    padding: 0 40px 48px;
        //    @include mobile {
        //        margin-top: -8px;
        //        padding: 0 0 32px;
        //    }
        //}
    }

    .recommended-curation {
        .inner {
            max-width: 1380 + 80px;
            margin: 0 auto;
            padding: 0 40px;
            @include mobile {
                max-width: 100%;
                padding: 0 16px;
            }
        }
        .title {
            @include blind;
            margin-bottom: 24px;
            font-weight: 700;
            @include font($font-large-2,34px);
            @include mobile {
                @include font($font-large-1,28px);
            }
        }
        .ui_smooth_scrolltab {
            position: relative;
            .ui_smooth_tab {
                margin: 0 -40px;
                @include mobile {
                    margin: 0 -16px;
                }
            }
            .scroll-controls {
                .btn-arrow {
                    position: absolute;
                    top: 0;
                    width: 40px;
                    height: 100%;
                    border-radius: 0;
                    &:before {
                        display: none;
                    }
                    &:after {
                        position: absolute;
                        top: 0;
                        width: 40px;
                        height: 100%;
                        content: '';
                    }
                    &.prev {
                        &:after {
                            left: 0;
                            background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
                        }
                    }
                    &.next {
                        &:after {
                            right: 0;
                            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
                        }
                    }
                }
                @include pc {
                    .btn-arrow {
                        padding: 0;
                        background-color: transparent;
                        &:before {
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 1;
                            width: 48px;
                            border-radius: 0;
                            height: 100%;
                            box-shadow: none;
                            opacity: 0;
                            transition: opacity 0.2s;
                        }
                        &.prev {
                            left: -40px;
                        }
                        &.next {
                            right: -40px;
                        }
                    }
                }
                @include mobile {
                    .btn-arrow {
                        &.prev {
                            left: -16px;
                        }
                        &.next {
                            right: -16px;
                        }
                    }
                }
            }
            //@include pc {
            //    &:hover {
            //        .scroll-controls {
            //            .btn-arrow {
            //                width: 48px;
            //                &:before {
            //                    opacity: 1;
            //                    transition: opacity 0.5s ease 0.3s;
            //                }
            //            }
            //        }
            //    }
            //}
        }
        .curation-list {
            padding: 0 40px;
            font-size: 0;
            white-space: nowrap;
            @include mobile {
                padding: 0 16px;
            }
            > li {
                display: inline-block;
                margin-left: 8px;
                &:first-child {
                    margin-left: 0;
                }
                &.on {
                    .curation {
                        border-color: $color-primary;
                        background-color: $color-white;
                        font-weight: 700;
                        color: $color-primary;
                    }
                }
            }
            .curation {
                display: block;
                padding: 10px 24px;
                border: 2px solid $color-bg;
                border-radius: 8px;
                background-color: $color-bg;
                @include font(16px,24px);
                color: $color-gray-1;
                @include mobile {
                    @include font(14px,22px);
                }
            }
        }
    }

    .smart-filter {
        margin-top: 32px;
        .inner {
            max-width: 1380 + 80px;
            margin: 0 auto;
            padding: 0 40px;
        }
        @include mobile {
            margin-top: 24px;
            .inner {
                max-width: 100%;
                padding: 0;
            }
        }
        .title {
            @include blind;
        }
        .filter-list {
            border-top: 1px solid $color-default;
            @include font-small;
            .row {
                display: table;
                width: 100%;
                border-bottom: 1px solid $color-border;
                @include mobile {
                    overflow: hidden;
                    table-layout: fixed;
                }
            }
            .label {
                display: table-cell;
                width: 115px;
                padding: 15px 0 15px 24px;
                font-weight: 700;
                vertical-align: top;
                @include mobile {
                    width: 100px;
                    padding: 16px 0 16px 16px;
                }
            }
            .content {
                position: relative;
                display: table-cell;
                padding: 14px 30px 14px 16px;
                @include mobile {
                    padding: 9px 0;
                    .ui_smooth_scrolltab {
                        &:before,
                        &:after {
                            position: absolute;
                            top: 0;
                            z-index: 1;
                            height: 100%;
                            content: '';
                        }
                        &:before {
                            left: 0;
                            width: 8px;
                            background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
                        }
                        &:after {
                            right: 0;
                            width: 40px;
                            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
                        }
                        .ui_smooth_tab {
                            padding-left: 8px;
                        }
                    }
                }
            }
            .chk-group {
                //margin-top: -12px;
                @include pc {
                    width: auto !important;
                }
                @include mobile {
                    margin-top: 0;
                    padding: 3px 40px 3px 0;
                    white-space: nowrap;
                }
            }
            .chk-wrap {
                @include pc {
                    margin: 8px 16px 0 0;
                }
                @include mobile {
                    margin-right: 4px;
                }
                input {
                    & + label {
                        padding: 2px 8px;
                        @include font-small;
                        &:before,
                        &:after {
                            display: none;
                        }
                        @include mobile {
                            padding: 4px 8px;
                        }
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .fold-box {
                @include pc {
                    overflow: hidden;
                    max-height: 72px;
                    margin-top: -8px;
                    &.unfold {
                        max-height: none;
                        .btn-fold {
                            transform: rotate(180deg);
                        }
                    }
                }
                .btn-fold {
                    position: absolute;
                    top: 15px;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    padding: 4px;
                    background: url('/lg5-common/images/icons/btn-down-16-black.svg') no-repeat center center;
                    transition: all .3s ease;
                    @include mobile {
                        display: none;
                    }
                }
            }
            .default {
                &.selected {
                    .chk-wrap input {
                        & + label {
                            color: $color-gray-1;
                        }
                        &:checked {
                            & + label {
                                border-radius: 50px;
                                background-color: #e4e4e4;
                            }
                        }
                    }
                }
            }
        }
        .btn-moreview {
            padding: 7px 16px;
            border-radius: 100px;
            &.block {
                margin-top: 24px;
            }
            &.fold {
                &:before {
                    background-image: url('/lg5-common/images/icons/btn-minus.svg');
                }
            }
            &.unfold {
                &:before {
                    transform:rotate(45deg);
                }
            }
        }

        .filter-result {
            position: relative;
            margin-top: 24px;
            padding: 24px;
            border-radius: 8px;
            background-color: $color-bg;
            font-size: 0;
            @include mobile {
                padding: 24px 16px;
                border-radius: 0;
            }
            @include pc {
                .ui_smooth_scrolltab {
                    margin-bottom: -8px;
                }
            }
            .btn-reset {
                position: absolute;
                top: 24px;
                left: 24px;
                z-index: 1;
                margin-right: 32px;
                padding: 7px 16px;
                border: 1px solid $color-default;
                border-radius: 100px;
                background-color: $color-bg;
                @include font-small;
                vertical-align: top;
                &:before {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                    background: url('/lg5-common/images/icons/icon-reset-48.svg') no-repeat center center;
                    background-size: 20px;
                    vertical-align: top;
                    content: '';
                }
                @include mobile {
                    left: auto;
                    right: 0;
                    margin-right: 0;
                    padding: 7px 16px 6px;
                    border: 0;
                    border-radius: 0;
                    &:before {
                        margin-right: 0;
                    }
                    span {
                        @include blind;
                    }
                }
            }
            .btn-delete {
                position: absolute;
                @include calc(top,50%,8px);
                right: 16px;
                width: 16px;
                height: 16px;
                background: url('/lg5-common/images/icons/btn-clear-16.svg') no-repeat center center;
            }
            .rounded-list {
                display: inline-block;
                //margin-bottom: -8px;
                @include pc {
                    width: auto !important;
                    li {
                        &:first-child {
                            padding-left: 131px;
                        }
                    }
                }
                .rounded {
                    position: relative;
                    padding: 7px 40px 7px 16px;
                }
                @include mobile {
                    margin-bottom: 0;
                    padding-right: 40px;
                    white-space: nowrap;
                    li {
                        margin-bottom: 0;
                    }
                }
            }
            .text {
                @include font-small;
            }
        }
    }

    .search-result-category {
        max-width: (1380px + 80px);
        margin: 32px auto 0;
        padding: 0 40px;
        .inner {
            position: relative;
            //padding-left: 84px; title 있을 경우 필요함
        }
        .ui_smooth_scrolltab {
            position: relative;
            .ui_smooth_tab {
                //margin-right: -40px;
            }
            .scroll-controls {
                .btn-arrow {
                    position: absolute;
                    top: 0;
                    width: 0;
                    height: 100%;
                    padding: 0;
                    border-radius: 0;
                    background-color: $color-white;
                    &:after {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        content: '';
                    }
                    &.prev {
                        left: 0;
                        &:after {
                            left: 0;
                            background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
                        }
                    }
                    &.next {
                        right: 0;
                        &:after {
                            right: 0;
                            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
                        }
                    }
                }
                @include pc {
                    .btn-arrow {
                        &:before {
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 1;
                            width: 38px;
                            border-radius: 0;
                            height: 100%;
                            background-color: transparent;
                            box-shadow: none;
                            opacity: 0;
                            transition: opacity 0.2s;
                        }
                        &:after {
                            width: 108px;
                        }
                    }
                }
                @include mobile {
                    .btn-arrow {
                        &:before {
                            display: none;
                        }
                        &:after {
                            width: 40px;
                        }
                    }
                }
            }
            @include pc {
                &:hover {
                    .scroll-controls {
                        .btn-arrow {
                            width: 38px;
                            &:before {
                                opacity: 1;
                                transition: opacity 0.5s ease 0.3s;
                            }
                        }
                    }
                }
            }
        }
        .title {
            display: none;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            font-weight: 700;
            @include font-small;
        }
        .rounded-list {
            white-space: nowrap;
            li {
                margin-bottom: 0;
            }
            .rounded {
                padding: 5px 16px;
                &:hover,
                &:focus {
                    border-color: $color-primary;
                    font-weight: 500;
                    color: $color-primary;
                }
            }
        }
        @include mobile {
            max-width: 100%;
            margin-top: 24px;
            padding: 0;
            //.inner { title 있을 경우 필요함
            //    padding-left: 76px;
            //}
            .ui_smooth_scrolltab {
                .ui_smooth_tab {
                    margin-right: 0;
                }
            }
            .rounded-list {
                padding: 0 16px;
            }
        }
    }
    .search-banner {
        max-width: (1380px + 80px);
        margin: 40px auto 0;
        padding: 0 40px;
        a {
            overflow: hidden;
            display: block;
            position: relative;
            height: 160px;
            border-radius: 8px;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            //transform: translateX(-50%);
            height: 100%;
            //width: 100%;
            //border-radius: 8px;
        }
        .text-area {
            position: absolute;
            top: 50%;
            left: 120px;
            transform: translateY(-50%);
            width: 100%;
            max-width: 640px;
            text-align: center;
            @media screen and (max-width: 1300px) {
                left: 0;
                padding: 0 80px;
            }
            .title {
                display: block;
                margin-bottom: 8px;
                @include font($font-large-1,30px);
                @include pc {
                    @include textEllipsis;
                }
            }
            .sub-copy {
                display: block;
                @include textEllipsis;
                @include font($font-medium,26px);
            }
        }
        br {
            display: none;
        }
        @include mobile {
            max-width: 100%;
            height: auto;
            margin: 32px 0 0;
            padding: 0 16px;
            a {
                height: auto;
            }
            img {
                position: static;
                width: 100%;
                height: auto;
            }
            .text-area {
                top: 12px;
                transform: translateY(0);
                max-width: calc(50% + 32px);
                padding: 0 16px;
                text-align: left;
                .title {
                    margin-bottom: 0;
                    @include font(13px,21px);
                    br {
                        display: block;
                    }
                }
                .sub-copy {
                    @include font($font-x-small,20px);
                }
            }
        }
    }
    .cont-wrap {
        @include clearfix;
        position: relative;
        margin-top: 48px;
        padding: 0 40px 0; /* BTOCSITE-32801 패딩바텀 제거 */
        //@media screen and (min-width: 1025px){
        //    &.w-filter {
        //        padding: 0 40px 120px 280px;
        //    }
        //}
        @include mobile {
            min-height:60vh; //BTOCSITE-2216
            margin-top: 16px;
            padding: 0 20px 60px;
            border-top: 0;
        }

        &.w-filter {
            @include tablet {
                .lay-filter {
                    .fiter-head,
                    .filter-head {
                        height: 88px;
                        border-bottom: 1px solid $color-border;
                        &:after {
                            display: none;
                        }
                        .tit {
                            //padding: 48px 0 16px;
                            //padding-bottom: 16px;   //BTOCSITE-2161
                        }
                    }
                    .plp-filter-wrap {
                        //padding:88px 16px 150px;
                        padding:88px 16px 48px;   //BTOCSITE-2161
                        .btn-reset,
                        .filter-close {
                            top: 44px;
                        }
                        .filter-top {
                            display: none;
                            padding-top: 20px;
                        }
                    }
                }
            }
            @media screen and (min-width: $tablet-l-width + 1) {
                .search-result-wrap {
                    float: left;
                    @include calc(width,100%,240px);
                    //min-height: 1000px;
                }
            }
        }
    }

    .main-wrap {
        padding:32px 0 120px;
        background:#f4f4f4;
        @include mobile {
            padding:24px 0 0;
        }

        .sorting-filter {
            @include clearfix;
            max-width:(1284px + 80px);
            margin:0 auto;
            padding:0 40px;
            @include mobile {
                max-width: 100%;
                padding:0 20px;
            }
            .sort-left {
                float:left;
                font-size:0;
                .count {
                    .num {
                        font-size:16px;
                        line-height: 24px;
                        color: #111;
                        font-weight: 500;
                    }
                }
            }
            .sort-right {
                float:right;
                font-size:0;
            }
            .sort-select-wrap {
                vertical-align:top;
                margin-left:40px;
                @include mobile {
                    margin-left:20px;
                }
                &:first-child {
                    margin-left:0;
                }
            }
        }
    }
}



.search-result-wrap {
    &.all {
        .list-sorting {
            display: none !important;
            &.fixed {
                display: block !important;
            }
        }
        .result-list-wrap {
            // [S] BTOCSITE-2059
            .btn-area {
                margin-top: 32px;
                text-align: center;
                .btn-link {
                    &.btn-moreview {
                        display: inline-block;
                        padding: 7px 14px;
                        border-radius: 20px;
                        text-decoration: none;
                        &:after {
                            display: none;
                        }
                    }
                }
                @include mobile {
                    margin-top: 24px;
                }
            }
            // [E] BTOCSITE-2059
        }
    }
    .result-area {
        position: relative;
        width: 100%;
    }

    .result-list-wrap {
        & + .result-list-wrap {
            margin-top: 22px;
        }
        .title {
            @include font($font-large-2,34px);
            &.type2 {
                @include font-large;
            }
            @include mobile {
                @include font($font-large-1,28px);
            }
        }
        @include mobile {
            position: relative;
            & + .result-list-wrap {
                margin-top: 40px;
            }
        }
        .list-wrap.care-list {
            .result-list {
                > li .result-info .info-text {
                    display: block;
                }
            }
        }
    }

    .list-head {
        @include clearfix;
        padding: 0 0 24px;
        .title {
            float: left;
        }
        .btn-area {
            float: right;
            .btn-link {
                margin-top: 5px;
                @include font-small;
                //&:after {
                    //top: calc( 50% - 10px);
                    //width: 6px;
                    //height: 6px;
                    //border-width: 1px 1px 0 0;
                //}
            }
        }
        @include mobile {
            padding: 0 0 16px;
            .btn-area {
                position: absolute;
                bottom: 0;
                right: 0;
                .btn-link {
                    margin-top: 0;
                }
            }
        }
    }

    .mobile-service-link {
        padding-top: 34px;
        font-size: 0;
        text-align: center;
        li {
            display: inline-block;
            width: 100%;
            padding: 0 9px;
            a {
                display: block;
                &:nth-child(2) {
                    margin-top: 8px;
                }
            }
        }
        @include pc {
            display: none;
        }
    }
}

@import "SERF9001";
@import "SERF9003";
@import "SERF9009";

// 에러페이지
@import "COMF2001";

//LGECOMVIO-16 제품 검색 고도화
//@import "SERF9001RE22";
@import "SERF9002RE22";



// 개인정보취급방침, 이벤트개인정보처리방침, 이용약관
.contents.term-wrap {
    border-top:8px solid #f4f4f4;
    &.bt-none {
        border-top:none;
    }
    .cont-wrap {
        border:none;
    }
    .private-info-wrap {
        max-width:1380px + 80px;
        margin:0 auto;
        padding:0 40px;
        @include mobile {
            padding:0;
        }
        .inner {
            @include mobile {
                margin-top:12px;
                padding:12px 35px 24px;
                background:#fff;
                &:first-child {
                    margin-top:0;
                }
            }
        }
        .inner-con {
            padding-bottom:40px;
            background:#fff;
            border-radius:4px;
            font-size:16px;
            line-height:26px;
            &:first-child {
                padding-top:48px;
            }
            @include mobile {
                padding:0;
                font-size:14px;
                line-height:18px;
                border-radius:inherit;
                &:first-child {
                    padding-top:0;
                }
            }
            .h2-tit {
                margin-bottom:8px;
                font-size:24px;
                line-height:34px;
                font-weight:700;
                text-align:center;
                // @include mobile {
                //     display:none;
                // }
                & + .txt {
                    text-indent: 14px;
                    strong {
                        font-weight: 400;
                    }
                }
                @include mobile {
                    font-size: 22px;
                    line-height: 31px;
                    & + .txt {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
            .select-dl {
                display:table;
                width:100%;
                padding:32px 77px;
                background-color:#f4f4f4;
                dt {
                    display:table-cell;
                    width:117px;
                    color:#616161;
                    font-size:14px;
                    line-height:20px;
                    @include mobile {
                        line-height:18px;
                    }
                }
                dd {
                    display:table-cell;
                    margin-top:8px;
                    @include mobile {
                        margin-top:12px;
                    }
                    .select-wrap {
                        max-width:100%;
                    }
                }
            }

            .tit {
                margin-top:64px;
                font-size:20px;
                font-weight:700;
                line-height:30px;
            }
            .tit + .txt {
                margin-top:24px;
                line-height:26px;
            }

            .private-list {
                @include clearfix;
                margin:32px 0 0 0;
                padding:40px;
                background-color:#f4f4f4;
                @include mobile {
                    display:block;
                    margin-top:40px;
                }
                .list-item {
                    float:left;
                    width:50%;
                    @include tablet {
                        padding-left:40px;
                    }
                    @include mobile {
                        float:none;
                        width:100%;
                        padding:16px 0 0 0;
                        &:first-child {
                            padding-top:0;
                        }
                    }
                    li {
                        margin-top:16px;
                        padding-right:24px;
                        &:first-child {
                            margin-top:0;
                        }
                        > a {
                            display:block;
                            color:#767676;
                            font-size:16px;
                            line-height:26px;
                            font-weight:500;
                            &:hover {
                                color:#000;
                                font-weight:700;
                                text-decoration:underline;
                            }
                        }
                    }
                }
            }

            .term-list {
                font-size:0;
                .list-item {
                    display:inline-block;
                    width:calc((100% - 64px) / 2);
                    margin-left:64px;
                    vertical-align:top;
                    @include mobile {
                        width:100%;
                        margin:0;
                    }
                    &:first-child {
                        margin:0;
                        > li:first-child {
                            margin-top:0;
                        }
                    }
                    > li {
                        display:table;
                        width:100%;
                        margin-top:32px;
                        table-layout:fixed;
                        &:first-child {
                            margin-top:0;
                        }
                        @include mobile {
                            margin-top:40px;
                            &:first-child {
                                margin-top:40px;
                            }
                        }
                        > a {
                            display:table-cell;
                            width:40%;
                            vertical-align:top;
                            color:#EC455A;
                            font-size:14px;
                            line-height:20px;
                            font-weight:500;
                            &:hover {
                                text-decoration:underline;
                            }
                            @include mobile {
                                display:block;
                                width:100%;
                                margin-bottom:16px;
                            }
                        }

                        > ol {
                            display:table-cell;
                            padding-left:32px;
                            vertical-align:top;
                            @include mobile {
                                display:block;
                                padding:0;
                            }
                            > li {
                                margin-top:8px;
                                &:first-child {
                                    margin-top:0;
                                }
                                > a {
                                    display:block;
                                    color:#000;
                                    font-size:14px;
                                    line-height:20px;
                                    font-weight:500;
                                    &:hover {
                                        text-decoration:underline;
                                    }
                                    @include mobile {
                                        line-height:18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .h3-tit {
            padding:40px 0 12px;
            font-size:18px;
            line-height:28px;
            font-weight:700;
            @include mobile {
                padding:0 0 32px;
                line-height:21px;
            }
        }

        .h4-tit {
            padding:32px 0 12px;
            color:#000;
            font-size:16px;
            line-height:24px;
            font-weight:normal;
            &:first-child {
                padding-top:0;
            }
            @include mobile {
                padding:40px 0 12px;
                font-size:14px;
                line-height:18px;
            }
        }

        ol, ul {
            li.mt {
                margin-top:24px;
                &:first-child {
                    margin-top:0;
                }
            }
        }

        .txt {
            & + ol,
                & + ul {
                margin-top:24px;
                @include mobile {
                    margin-top:40px;
                }
            }
            & + .tb_row {
                margin-top:32px;
                @include mobile {
                    margin-top:40px;
                }
            }
            & + .txt {
                margin-top:32px;
                @include mobile {
                    margin-top:40px;
                }
            }
            &.center {
                text-align:center;
            }
        }


        dl.con-dl {
            margin-top:32px;
            @include mobile {
                margin-top:40px;
            }
            & + dl.con-dl {
                margin-top:64px;
            }
            dt {
                font-size:14px;
                font-weight:700;
                line-height:24px;
            }
            dd {
                margin-top:14px;
                ol, ul {
                    margin-top:0;
                }
                .bullet-list {
                    margin-top:12px;
                    @include mobile {
                        margin-top:20px;
                    }
                }
            }
        }

        .tb_row {
            .btn-text {
                position:relative;
                padding-right:22px;
                font-size:14px;
                line-height:24px;
                font-weight:500;
                vertical-align:baseline;
                &:after {
                    content:' ';
                    position:absolute;
                    top:3px;
                    right:0;
                    width:20px;
                    height:20px;
                    background:url('/lg5-common/images/icons/btn-arr-20x20-gray.svg') no-repeat 0 0;
                }
            }
        }

        .total-tit {
            margin:24px 0 16px;
            @include mobile {
                margin:40px 0 12px;
                padding:0 20px;
            }
            .num {
                display:inline-block;
                font-size:14px;
                line-height:24px;
                font-weight:700;
                vertical-align:middle;
            }
        }

        .ev-private-list {
            li {
                margin-top:8px;
                @include mobile {
                    margin-top:4px;
                }
                &:first-child {
                    margin-top:0;
                }
                a {
                    display:block;
                }
                .item {
                    padding:32px 56px;
                    background:#fff;
                    @include mobile {
                        padding:24px 20px;
                    }
                    .tit {
                        font-size:16px;
                        line-height:24px;
                        font-weight:700;
                        @include mobile {
                            font-size:14px;
                            line-height:18px;
                        }
                    }
                    .date {
                        display:block;
                        margin-top:4px;
                        color:#616161;
                        font-size:14px;
                        line-height:20px;
                        @include mobile {
                            margin-top:6px;
                            font-size:12px;
                            line-height:16px;
                        }
                    }
                }
            }
        }

        &.term-info {
            .inner-con {
                color:#616161;
                font-size:14px;
                line-height:20px;
                .txt {
                    & + ol,
                        & + ul {
                        margin-top:20px;
                        @include mobile {
                            margin-top:12px;
                        }
                    }
                }
                dl.con-dl {
                    margin-top:20px;
                    @include mobile {
                        margin-top:12px;
                    }
                    dd {
                        margin-top:0;
                    }
                }
            }
        }
    }

}
// 이메일 무단수집 거부 이전 버튼 위치 수정
.wrap:has(.contents.term-wrap) {
    .header.helloBar~.mobile-nav-wrap {
        top: auto;
    }
}


// 소식지 신청
.align-center {
    text-align:center;
}
.pop-conts.term-pop {
    padding-top:12px;
    .desc {
        font-size:16px;
        line-height:26px;
    }
    .input-info {
        margin-top:48px;
        @include mobile {
            margin-top:24px;
        }
        & + .input-info {
            margin-top:64px;
            @include mobile {
                margin-top:60px;
            }
        }
        &:before {
            display:none;
        }
        .tit-wrap {
            .h2-tit {
                font-size:20px;
                line-height:30px;
            }
        }
        .form-wrap {
            .forms {
                &:first-child {
                    margin-top:24px;
                }
                @include mobile {
                    &:first-child {
                        margin-top:20px;
                    }
                }
            }
        }
        dd.conts {
            font-size:0;
            .input-wrap {
                max-width:calc(100% - 142px);
                @include mobile {
                    max-width:100%;
                }
            }
            .select-list-wrap {
                padding-top:8px;
                > ul {
                    > li {
                        width:14%;
                        padding-left:0;
                        @include mobile {
                            width:auto;
                            margin-top:12px;
                        }
                    }
                }
            }
            .select-wrap {
                max-width:calc((100% - 150px) /2);
                margin:0 0 0 8px;
                @include mobile {
                    max-width:100%;
                    margin:8px 0 0 0;
                }
                &:first-child {
                    margin:0;
                }
            }
        }
        .inner {
            & + .inner {
                margin-top:48px;
                @include mobile {
                    margin-top:41px;
                }
            }
            margin-top:24px;
            @include mobile {
                margin-top:20px;
            }
            .h3-tit {
                margin-bottom:12px;
                font-size:14px;
                font-weight:700;
                line-height:24px;
                @include mobile {
                    font-size:13px;
                    line-height:21px;
                }
            }
        }
        .agree-cont-wrap {
            .agree-cont-box {
                max-height:200px;
                border-radius:8px;
                @include mobile {
                    max-height:160px;
                }
            }
            .agree-input {
                margin-top:16px;
                padding:0;
                border:none;
                @include mobile {
                    margin-top:13px;
                }
            }
        }
    }
    .tb_row {
        margin-top:24px;
        @include mobile {
            margin-top:12px;
        }
    }
    .tit-wrap {
        + .desc {
            margin-top:-20px;
        }
    }


}


// list-sorting
.btn-filter {
    &:before {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .btn-filter {
        //position: relative;
        top: 0;
        left: 0;
        z-index:0;  // BTOCSITE-2161
        .ico-filter {
            top: 0;
            width: auto;
            margin-right: 0;
            padding-left: 40px;
            background-position: left center;
            font-weight: 700;
            font-size: 13px;
            line-height: 32px;
        }
    }
}

.list-sorting {
    //display: none;
    // BTOCSITE-7149 검색 > 검색바로 가기 새창 오류
    &.selected {
        .sort-area {
            .sort-check-area.active,.sort-check-area,.sort-select-wrap,.sort-search-area  {
                display:none;
            }
        }
    }

    @include tablet {
        //padding: 44px 0 16px;
        &.selected {
            padding-top: 0;
        }
    }
    .input-keyword-wrap {
        display: none;
    }
    .sort-area {
        &.left {
            padding-top: 5px;
        }
        .sort-select-wrap {
            vertical-align: middle;
        }
        .sort-check-area {
            display: none;
            vertical-align: middle;
            .chk-wrap input + label {
                @include font-small;
            }

            //BTOCSITE-16 검색 결과 구획 정리
            &.active {
                display:inline-block;
            }

            &:first-child {
                margin-right:16px;

                @include mobile {
                    margin-right:18px;
                }
            }
        }
        .sort-search-area {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            margin-left: 24px;
            .search-inner {
                 width: 200px;
                 height: 36px;
                 padding: 6px 52px 6px 16px;
                 border-radius: 17px;
                 background-color: #f4f4f4;
                 .inp-txt {
                    width: 100%;
                    background-color: #f4f4f4;
                    font-size: 14px;
                    line-height: 24px;
                    color: #767676;
                 }
                 .btn-sch {
                    display: inline-block;
                    position: absolute;
                    top: 7px;
                    right: 16px;
                    width: 20px;
                    height: 20px;
                    background: url('/lg5-common/images/icons/icon-search-20.svg');
                }
            }
        }
        @media screen and (max-width: 1024px) {
            &.left {
                padding-top: 0;
            }
            // BTOCSITE-7149 검색 > 검색바로 가기 새창 오류
            // .sort-check-area {
            //     position: absolute;
            //     top: 4px;
            //     right: 0;
            // }
            .sort-search-area {
                display: none;
            }
        }
    }
    &.fixed {
        // display: block;
        display: none; // BTOCSITE-27053 자동완성 사용성 개선
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 94px;
        padding-bottom: 0;
        background: #fff;
        z-index: 102;
        //box-shadow: 0 4px 12px 0 rgba(0,0,0,0.14);
        .list-sorting-inner {
            display: flex;
            //align-items: center;
            max-width: calc(1380px + 80px);
            margin: 0 auto;
            padding: 12px 40px 20px;
            height: 100%;
            .sort-area {
                float: none;
                &.left {
                    align-self: flex-end;
                    padding: 0 0 6px;
                }
                .list-count {
                    margin-top: 0;
                }
                .sort-list {
                    display: flex;
                    align-items: flex-end;
                    align-content: flex-end;
                    height: 100%;
                    .input-keyword-wrap {
                        display: block;
                        flex: 1;
                        margin-left: 24px;
                        margin-bottom: 0;
                        padding: 0;
                    }
                    .sort-check-area,
                    .sort-select-wrap {
                        padding-bottom: 6px;
                    }
                    .sort-search-area,
                    .sort-select-wrap {
                        margin-left: 16px;
                    }
                }
            }
            .sort-area + .sort-area,
            .sort-area.right {
                flex: 1;
                margin-left: 16px;
            }
        }
        @include tablet {
            padding: 0;
            .list-sorting-inner {
                padding: 0 40px;
            }
            .sort-area.left {display: none}
            .sort-area.right {
                margin-left: 0 !important;
                .sort-list {
                    margin: 0;
                    .sort-check-area,
                    .sort-search-area,
                    .sort-select-wrap {
                        display: none
                    }
                    .input-keyword-wrap {
                        margin: 0;
                        .input-keyword {
                            max-width: 100%;
                            //padding: 0 !important;
                        }
                    }
                }
            }

        }
        @include mobile {
            height: 70px;
            box-shadow: none;
            padding-bottom: 12px;
            .list-sorting-inner {
                padding: 0;
            }
        }
    }
}


.mobile-service-link {
    display: none;
}
@media screen and (max-width: 1024px) {
    .mobile-service-link {
        display: block;
        margin-top:30px;
        text-align:center;
        li {
            margin-top: 8px;
            &:first-child {
                margin-top: 0;
            }
        }
        .btn-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 72px;
        padding: 24px 16px 24px 20px;
        background-color: #f4f4f4;
        border-radius: 8px;
        @include font-small;
        font-weight: 700;
        span {
            padding-right: 12px;
        }
        img {
            width: 40px;
            height: 40px;
        }
        }
    }
}

.flag-wrap {
    .flag {
        &:before {
            display: none !important;
        }
        &:not(:last-child):after {
            display: inline-block;
            width: 1px;
            height: 10px;
            margin: 0 4px;
            background-color: #adadad;
            vertical-align: -1px;
            content: '';
        }
    }
}

.search-not-result {
    clear: both;
    .no-data {
        margin-top: 80px;
        padding: 0;
        p {
            padding-top: 0;
            @include font-medium;
            &:before {
                display: none;
            }
            span {
                display: block;
                margin-bottom: 8px;
                font-weight: 700;
                @include font($font-large-4,52px);
                color: $color-default;
                word-break: break-all;
                em {
                    word-break: break-all;
                }
            }
        }

        @include mobile {
            padding: 0 16px 60px;
            p {
                span {
                    margin-bottom: 12px;
                    @include font($font-large-2,33px);
                }
            }
        }
    }
    @at-root .contents.search .cont-wrap & {
        .no-data {
            margin-top: 0;
            @include pc {
                margin-bottom: 80px;
            }
            @include mobile {
                padding-bottom: 60px;
            }
        }
    }
}

// LGECOMVIO-16
.search.re22 {
    .search-result-category {
        .ui_smooth_scrolltab {
            position: relative;
            .ui_smooth_tab {
                margin-right: 0;
            }
        }
    }
    .cont-wrap {
        .search-result-wrap {
            float: left;
            width: calc( 100% - 234px);
            &.no_filter {
                width: 100%;
            }
            @include mobile {
                float: none;
                width: 100%;
            }
            @include tablet {
                float: none;
                width: 100%;
            }
        }
        @include mobile {
            margin-top: 0;
            padding: 0 0 60px;
        }

        &.w-filter {
            @include tablet {
                .lay-filter {
                    .fiter-head,
                    .filter-head {
                        height: auto;
                        border-bottom: 0;
                    }
                    .plp-filter-wrap {
                        padding:56px 0 48px;
                        .btn-reset,
                        .filter-close {
                            top: 11px;
                        }
                    }
                }
            }
            @media screen and (min-width: $tablet-l-width + 1) {
                .search-result-wrap {
                    width: calc( 100% - 234px);
                }
            }
        }
    }
    
    @include mobile {
        &.service-gate {
            .cont-wrap {
                min-height: initial;
            }
        }
    }
    
    .result-list-wrap {
        .title {
            @include font($font-large,26px);
        }
        @include mobile {
            padding: 0 16px;
        }
        &.list-no-data {
            .list-wrap {
                margin-top: 16px;
            }
        }
    }
    .list-head {
        padding: 0 0 0 8px;
    }
    //B필터 고정
    .cont-wrap {
        @include pc {
            &.filter_fixed {
                .lay-filter {
                    position: fixed;
                    top: 94px;
                    height: calc(100vh - 94px);
                    overflow-y: auto;
                    -ms-overflow-style:none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .lay-filter-con {
                        .plp-filter-wrap {
                            .anchor_choice {
                                position: fixed;
                                width: 210px;
                                z-index: 2;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    bottom: -32px;
                                    width: 100%;
                                    height: 32px;
                                    background: linear-gradient(#fff,transparent);
                                }
                            }
                        }
                    }
                }
                .search-result-wrap {
                    padding-left: 234px;
                    width: 100%;
                    &.no_filter {
                        padding: 0;
                    }
                }
            }
            // &.bottom { /* BTOCSITE-32801 필터오류 */ 
            //     .lay-filter {
            //         position: absolute;
            //         bottom: 0;
            //         top: initial;
            //         .lay-filter-con {
            //             .plp-filter-wrap {
            //                 .anchor_choice {
            //                     top: 94px;
            //                 }
            //             }
            //         }
            //     }
            //     .search-result-wrap {
            //         padding-left: 234px;
            //         width: 100%;
            //     }
            // }
        }
        @include tablet {
            .search-result-wrap {
                padding-left: 0 !important;
                width: 100%;
            }
        }
    }
    //A필터 고정시 쉐도우 처리
    .cont-wrap {
        @include mobile {
            &.filter_fixed {
                .search-result-wrap {
                    .result-area {
                        .afilter_area {
                            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    //A필터 고정
    .afilter_area {
        @include mobile {
            position: sticky;
            top: 58px;
            background-color: #fff;
            z-index: 8;
        }
    }


    .btn-filter {
        &:before {
            display: block;
        }
    }
    @media screen and (max-width: 1024px) {
        .btn-filter {
            left: initial;
        }
    }
    .list-sorting {
        &.fixed {
            @include tablet {
                .sort-area.right {
                    .sort-list {
                        .input-keyword-wrap {
                            margin: 0 0 15px 0;
                        }
                    }
                }
            }
            @include mobile {
                .sort-area.right {
                    .sort-list {
                        .input-keyword-wrap {
                            margin: 0;
                        }
                    }
                }
            }
            &.insort {
                position: static;
                height: auto;
                //padding-bottom: 30px;
                box-shadow: none;
            }
        }
    }
    .search-not-result {
        clear: both;
        .no-data {
            p {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                .search_word_area {
                    display: inline-block;
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #666;
                    word-break: break-all;
                    @include mobile {
                        margin-bottom: 4px;
                    }
                    .search_word {
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: 700;
                        color: #666;
                        line-height: 20px;
                        @include mobile {
                            font-size: 12px;
                        }
                        .word_area {
                            display: inline-block;
                            vertical-align: middle;
                            max-width: 250px;
                            margin-bottom: 0;
                            @include textEllipsis;
                            @include font(14px,20px);
                            font-weight: 700;
                            color: #666;
                            @include mobile {
                                max-width: 160px;
                                @include font(12px,17px);
                            }
                        }
                        em {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 8px;
                            word-break: break-all;
                            @include mobile {
                                margin-left: 4px;
                            }
                        }
                    }
                }
                .search_word_msg {
                    display: block;
                    margin-top: 8px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #000;
                    line-height: 26px;
                    @include mobile {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    em {
                        font-weight: 700;
                    }
                }
                .search_word_link {
                    display: inline-block;
                    margin-top: 8px;
                    padding-right: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center/ 16px;
                    color: #000;
                    @include mobile {
                        margin-top: 8px;
                        font-size: 12px;
                        line-height: 17px;
                    }
                }
            }

            @include mobile {
                margin-top: 40px;
                padding: 0 16px 40px;
                p {
                    span {
                        margin-bottom: auto;
                        font: initial;
                        line-height: initial;
                    }
                }
            }
        }
        @at-root .contents.search .cont-wrap & {
            .no-data {
                @include pc {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
