.recommend-list-box {
    clear: both;
    max-width: 1380px;
    //margin: 56px auto 0;
    padding-top: 56px;
    @media screen and (min-width: $tablet-l-width + 1) {
        padding-left: 240px;
    }
    @include mobile {
        padding-top: 40px;
    }

    .title {
        padding-bottom: 32px;
        @include font($font-large-2,34px);
        color: #000;
        text-align: center;
        @include mobile {
            padding-bottom: 24px;
            @include font($font-large-1,28px);
        }
    }

    .box-list-inner {
        font-size: 0;
        margin: -12px -12px 0;
        .lists {
            display: inline-block;
            position: relative;
            width: calc(100% / 2);
            margin-top: 12px;
            padding: 0 12px;
            vertical-align: top;
            @include mobile {
                width: 100%;
            }
        }
        .list-inner {
            position: relative;
            display: table;
            width: 100%;
            padding: 0;
            border-radius: 4px;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.06);
            @include mobile {
                display: block;
                border-radius: 8px;
            }
            .thumb {
                position: relative;
                display: table-cell;
                vertical-align: middle;
                @include pc {
                    overflow: hidden;
                    width: 15.925vw;
                    height: 228px;
                    border-radius: 8px 0 0 8px;
                    img {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        //max-width: 100%;
                        height: 100%;
                        //border-radius: 8px 0 0 8px;
                    }
                }
                @include mobile {
                    display: block;
                    img {
                        width: 100%;
                        border-radius: 8px 8px 0 0;
                    }
                }
            }
            .info {
                display: table-cell;
                position: relative;
                //width: 270px;
                padding: 32px 24px 60px;
                @include mobile {
                    display: block;
                    width: 100%;
                    padding: 17px 27px;
                }
            }
            .btn-area {
                margin-top: 16px;
                .btn-link {
                    @include font-small;
                }
                @include mobile {
                    margin-top: 8px;
                }
            }
            .tit {
                overflow: hidden;
                width: 100%;
                max-height: 56px;
                margin-bottom: 4px;
                @include font($font-medium,26px);
                text-overflow: clip;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: #000;
                font-weight: 700;
                @include mobile {
                    max-height: 48px;
                    @include font($font-medium - 1,23px);
                }
            }
            .copy {
                margin-top: 4px;
                @include font($font-small,24px);
                color: #000;
                @include mobile {
                    margin-top: 2px;
                    line-height: 22px;
                }
            }
            
        }
    }
}