.search-result-wrap {
    &.suggested-list-wrap {
        width: 100%;
        .product-items {
            .item {
                .product-contents {
                    height: 170px;
                }
                .product-info {
                    & + .badge-benefit {
                        margin-top: 56px;
                    }
                }
                @include mobile {
                    .product-contents {
                        height: auto;
                    }
                }
            }
        }
    }
}
