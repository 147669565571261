//전체보기 비교하기 대응
html {
    .contents.search.re22.compare-close {
        .result-list-wrap .list-wrap .result-allview_area .result-allview.fixed {
            bottom: 120px;
        }
    }
    &.app {
        @include mobile {
            .contents.search.re22.compare-close {
                .result-list-wrap .list-wrap .result-allview_area .result-allview.fixed {
                    bottom: 60px;
                }
            }
        }
    }
}

//GNB 대응
.contents.search.re22 {
    @include mobile {
        min-height: 100vh;
        margin-top: -46px;

        &.service-gate {
            min-height: initial;
        }
    }
}
.search-btn{
    display: inline-block;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
}
.search-btn.fix{
    width: 180px;
}
.search-btn.black{
    background-color: #222;
    color: #fff;
    &:hover {
        background-color: #222;
    }
    &:active {
        background-color: #222;
    }
}
.feedback-box-wrap{
    max-width: 1460px;
    margin: 40px auto 0 auto;
    padding: 0 40px;
    @include mobile {
        padding: 0 16px;
        margin:0 0 40px 0;
    }
    .feedback-box {
        position: relative;
        padding: 32px;
        border-radius: 16px;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        background-color: #fff;
        @include mobile {
            padding: 20px 16px 16px 16px;
        }
        .fb_title{
            font-size: 14px;
            line-height: 20px;
            color: #666;
            @include mobile {
                font-size: 12px;
                line-height: 17px;
            }
        }
        .fb_msg{
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            @include mobile {
                margin-top: 4px;
                font-size: 16px;
                line-height: 22px;
            }
        }
        .select-list-wrap{
            margin: 24px 0 0 0;
            @include mobile {
                margin: 4px 0 0 0;
            }
            li{
                margin-top: 0;
                padding:0 48px 0 0;
                @include mobile {
                    margin-top: 16px;
                }
                .rdo-wrap{
                    input + label{
                        font-size: 12px;
                        line-height: 24px;
                        @include mobile {
                            font-size: 14px;
                            line-height: 24px;
                        }
                        &::after {
                            background-image: url("/lg5-common/images/icons/btn-radio-off-24-black.svg");
                        }
                    }
                    input {
                        &:checked + label {
                            &::after {
                                background-image: url("/lg5-common/images/icons/btn-radio-on-24-black.svg");
                            }
                        }
                        &:disabled + label {
                            &::after {
                                background-image: url("/lg5-common/images/icons/btn-radio-disabled-24-black.svg");
                            }
                        }
                    }
                }
            }
            &.w-auto {
                > ul{
                    margin: 0;
                    li{
                        @include mobile {
                            width: 50%;
                        }
                    }
                }
            }
        }
        .search-btn{
            position: absolute;
            bottom: 32px;
            right: 32px;
            @include mobile {
                width: 100%;
                display: block;
                position: static;
                height: 44px;
                border-radius: 8px;
                font-size: 13px;
                line-height: 44px;
            }
        }
    }
    .feedback-input {
        margin-top: 48px;
        @include mobile {
            margin-top: 28px;
        }
        .input_title {
            margin-bottom: 16px;
            @include font(16px,24px);
            font-weight: 700;
            @include mobile {
                margin-bottom: 8px;
                @include font(14px,20px);
            }
        }
        .input-wrap {
            width: 100%;
            padding-right: 300px;
            @media screen and (min-width: 768px) and (max-width:1024px) {
                width: 100%;
                padding-right: 200px;
            }
            @include mobile {
                width: 100%;
                padding: 0;
                margin-bottom: 16px;
            }
            textarea {
                height: 96px;
                @include mobile {
                    height: 106px;
                }
                &:focus {
                    outline: none;
                    border-color: #ddd;
                }
            }
        }
    }
}

// BTOCSITE-23248 이메일 상담
.email-consulting-wrap{
    max-width: 1460px;
    margin: 80px auto 0 auto;
    padding: 0 40px;
    @include mobile {
        padding: 0 16px;
        margin:60px 0 60px 0;
    }
    .email-title{
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        @include mobile {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 24px;
        }
    }
    .email-main-box{
        padding: 32px;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 2px 4px 12px 0 rgba(0,0,0,0.14);
        position: relative;
        @include mobile {
            padding: 16px;
        }
        .email-box{
            display: flex;
            align-items: center;
            @include mobile {
                align-items: start;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .img-box{
                display: block;
                width: 60px;
                height: 60px;
                margin-right: 20px;
                @include mobile {
                    width: 36px;
                    height: 36px;
                    margin-right: 0;
                }
                img{
                    height: 100%;
                }
            }
            .email-msg{
                font-size: 18px;
                line-height: 26px;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
                br:first-child{
                    display: none;
                    @include mobile {
                        display: block;
                    }
                }
            }
        }
        .email-consulting-btn{
            position: absolute;
            bottom: 50%;
            right: 32px;
            transform: translateY(50%);
            @include mobile {
                display: block;
                position: static;
                transform: translateY(0);
                width: 100%;
                height: 44px;
                margin-top: 20px;
                font-size: 13px;
                line-height: 44px;
                border-radius: 8px;
            }
        }
    }
    
}

// BTOCSITE-23248 타이틀 css 수정
.contents.search.re22{
    .cont-wrap{
        .search-result-wrap{
            .result-area{
                .list-head{
                    margin-bottom: 24px;
                    @include mobile {
                        margin-bottom: 20px;
                    }
                    .title{
                        font-size: 24px;
                        line-height: 32px;
                        @include mobile {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
                // .list-wrap{
                    // margin-top: 24px;
                    // @include mobile {
                    //     margin-top: 20px;
                    // }
                // }
            }
        }
    }
}


// list-sorting
.result-area > .list-sorting {
    padding-bottom: 0;
    @include mobile {
        //padding: 12px 16px 6px 16px !important;
    }
    .sort-area.left {
        padding-top: 0;
    }
}

// 서비스 검색
.result_service {
    @include mobile {
        padding: 0 16px;
    }
    .service_info {
        max-width: 911px;
        margin: 100px auto 72px auto;
        padding-top: 88px;
        background: no-repeat center top/80px;
        text-align: center;
        @include mobile {
            max-width: 100%;
            margin: 40px 0 0 0;
            padding-top: 58px;
            background: no-repeat center top/50px;
        }
        .service_title {
            @include font(28px,36px);
            font-weight: 700;
            @include mobile {
                @include font(20px,26px);
            }
        }
        .service_content {
            margin-top: 4px;
            @include font(18px,26px);
            color: #666;
            @include mobile {
                padding: 0 48px;
                @include font(13px,19px);
            }
        }
        .service_link {
            margin-top: 40px;
            text-align: center;
            @include mobile {
                margin-top: 47px;
            }
            a {
                display: inline-block;
                width: 240px;
                border-radius: 20px;
                background-color: #222;
                @include font(14px,40px);
                color: #fff;
                text-align: center;
                span {
                    display: inline-block;
                }
                &.outlink {
                    span {
                        padding-right: 24px;
                        background:#222 url("/lg5-common/images/icons/btn-boxoutlink-20.svg") no-repeat right center/20px;
                    }

                }
                @include mobile {
                    width: 100%;
                    border-radius: 8px;
                    @include font(16px,50px);
                    &.outlink {
                        span {
                            padding-right: 20px;
                            background:#222 url("/lg5-common/images/icons/btn-boxoutlink-20.svg") no-repeat right center/16px;
                        }
                    }
                }
            }
        }
    }
    &.type1 {
        .service_info {
            background-image: url("/lg5-common/images/icons/icon-service-80-type1.svg");
        }
        .service_chatbot {
            .chatbot_txt {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    &.type2 {
        .service_info {
            background-image: url("/lg5-common/images/icons/icon-service-80-type2.svg");
        }
        .service_chatbot {
            .chatbot_txt {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    &.type3 {
        .service_info {
            background-image: url("/lg5-common/images/icons/icon-service-80-type3.svg");
        }
        .service_chatbot {
            .chatbot_txt {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    &.type4 {
        .service_info {
            background-image: url("/lg5-common/images/icons/icon-service-80-type3.svg");
        }
        .service_chatbot {
            .chatbot_txt {
                & + .chatbot_txt {
                    display: none;
                }
            }
        }
    }
    &.type5 {
        .service_chatbot {
            .chatbot_txt {
                &:first-child {
                    display: none;
                }
            }
        }
    }
    .service_chatbot {
        max-width: 911px;
        margin: 0 auto;
        border-top: 1px solid #ddd;
        padding-top: 72px;
        @include mobile {
            padding-top: 60px;
            border-top: none;
        }
        .chatbot_txt {
            @include font(20px,28px);
            font-weight: 500;
            @include mobile {
                @include font(18px,24px);
                font-weight: 700;
            }
        }
        .chatbot_box {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            @include mobile {
                display: block;
                margin-top: 20px;
            }
            a {
                display: block;
                flex: 1;
                padding: 30px 32px 30px 112px;
                border-radius: 16px;
                border: solid 1px #ddd;
                background-color: #fff;
                background: no-repeat left 32px center/60px;
                & + a {
                    margin-left: 24px;
                }
                @include mobile {
                    padding: 16px 16px 16px 64px;
                    background: no-repeat left 16px top 18px/36px;
                    & + a {
                        margin:12px 0 0 0;
                    }
                }
                &.box_customer {
                    background-image: url("/lg5-common/images/icons/icon-chatbot-60-customer.svg");
                }
                &.box_purchase {
                    background-image: url("/lg5-common/images/icons/icon-chatbot-60-purchase.svg");
                }
                .box_tit {
                    display: block;
                    @include font(16px,24px);
                    font-weight: 700;
                    @include mobile {
                        @include font(15px,21px);
                        font-weight: 500;
                    }
                }
                .box_cnt {
                    display: block;
                    @include font(12px,18px);
                    color: #666;
                    @include mobile {
                        margin-top: 2px;
                        @include font(12px,17px);
                        color: #666;
                    }
                }
                .box_link {
                    display: inline-block;
                    clear: both;
                    margin-top: 4px;
                    padding-right: 16px;
                    @include font(12px,18px);
                    background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center/16px;
                    @include mobile {
                        @include font(12px,17px);
                        margin-top: 8px;
                        background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center/12px;
                    }
                }
            }
        }
    }
    &.type4 {
        .service_chatbot {
            padding-top: 52px;
            border-top: none;
            .chatbot_txt {
                @include font(28px,36px);
                text-align: center;
                @include mobile {
                    @include font(20px,26px);
                }
            }
            .chatbot_box {
                margin-top: 40px;
            }
        }
    }
}

// 통합검색 상세보기 팝업
.morelist-layer {
    height: 100%;
    border-radius: 0;
    background-color: #fff;
    &::before {
        content: none;
    }
    &.popup-wrap.full {
        max-width: 100%;
    }
    .inner {
        max-width: (832px + 80px);
        margin: 0 auto;
        padding: 180px 40px;
        @include mobile {
            max-width: 100%;
            padding: 42px 16px 16px 16px;
        }
        .morelist_tit {
            @include font(24px,32px);
            font-weight: 500;
            margin-bottom: 24px;
            @include mobile {
                @include font(18px,24px);
                margin-bottom: 16px;
            }
            .prod_name {
                font-weight: 700;
                margin-right: 4px;
            }
            .list_count {
                font-weight: 400;
                color: #ea1917;
                margin-left: 4px;
            }
        }
        .list-sorting {
            padding-bottom: 16px;
            @include mobile {
                padding:0 0 12px 0;
            }
        }
        .morelist_wrap {
            li {
                margin-top: 24px;
                padding: 16px 32px;
                border-radius: 16px;
                box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
                &:first-child {
                    margin-top: 0;
                }
                @include mobile {
                    margin-top: 12px;
                    padding: 20px 14px;
                }

            }
            &.supplies {
                li {
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    padding: 24px 32px;

                    @include mobile {
                        padding: 20px 12px 20px 16px;
                    }

                    & > a{
                        display: inline-block;
                        width: 116px;

                        @include mobile {
                            width: 80px;
                        }

                        img {
                            width: 100%;
                        }
                    }
                    
                    .smore_cnt {
                        flex: 1;
                        position: relative;
                        overflow: hidden;
                        display: block;
                        min-height: 116px;
                        padding-left: 38px;
                        @include mobile {
                            min-height: 80px;
                            padding-left: 16px;
                        }

                        .smore_prod-wrap {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .smore-prod-info {
                                display: block;
                                width: calc(100% - 60px);
                            }

                            .btn-cart {
                                position: relative;
                                z-index: 1;
                                width: 40px;
                                height: 40px;
                                background: #fff url(/lg5-common/images/icons/ico_cart_48.svg) no-repeat 50% 50%;
                                background-size: 24px 24px;
                                border: 1px solid #ddd;
                                border-radius: 8px;

                                @include mobile {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }

                        .smore_prod {
                            display: block;
                            margin-top: 6px;
                            @include mobile {
                                margin-top: 0;
                            }
                            .cata {
                                display: block;
                                @include font(14px,20px);
                                @include mobile {
                                    @include font(10px,15px);
                                }
                            }
                            .name {
                                display: block;
                                @include font(18px,26px);
                                font-weight: 700;
                                @include textEllipsisClamp(2);
                                @include mobile {
                                    @include font(13px,19px);
                                }
                            }
                            .model {
                                display: block;
                                margin-top: 8px;
                                @include font(12px,18px);
                                color: #666;
                                @include mobile {
                                    margin-top: 0;
                                    @include font(10px,15px);
                                }
                            }
                        }
                        .pay_area {
                            &.sold_out {
                                .smore_pay {
                                    .salerate {
                                        color: rgba(234, 25, 23, 0.5);
                                        @include pc {
                                            margin-left: 16px;
                                        }
                                        @include mobile {
                                            & + .sale {
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                    .sale {
                                        margin-right: 4px;
                                        color: #666;
                                    }
                                    .orig {
                                        color: #aaa;
                                        @include mobile {
                                            margin-right: 4px;
                                        }
                                    }
                                    &::before {
                                        content: "일시품절";
                                        display: inline-block;
                                        //margin-right: 16px;
                                        padding: 0 8px;
                                        border-radius: 4px;
                                        background-color: #666;
                                        @include font(12px,20px);
                                        color: #fff;
                                        @include mobile {
                                            content: none;
                                        }
                                    }
                                    &::after {
                                        content: "일시품절";
                                        display: inline-block;
                                        vertical-align: middle;
                                        //margin-left: 8px;
                                        padding: 0 6px;
                                        border-radius: 4px;
                                        background-color: #666;
                                        @include font(10px,19px);
                                        color: #fff;
                                        @include pc {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                        .smore_pay {
                            display: block;
                            text-align: right;

                            a {
                                display: inline-block;
                                margin-top: 9px;
                                font-size: 0;
                                vertical-align: middle;

                                @include mobile {
                                    display: block;
                                    margin-top: 0;
                                }
                            }

                            @include mobile {
                                text-align: left;
                                margin: 6px 0 0 0;
                            }
                            .salerate {
                                display: inline-block;
                                vertical-align: middle;
                                @include font(16px,24px);
                                color: #ea1917;
                                & + .sale {
                                    margin-left: 8px;
                                }
                                @include mobile {
                                    & + .sale {
                                        margin-left: 4px;
                                    }
                                    @include font(13px,19px);
                                }
                            }
                            .sale {
                                display: inline-block;
                                margin-left: 16px;
                                vertical-align: middle;
                                @include font(16px,24px);
                                font-weight: 700;
                                @include mobile {
                                    margin-left: 0;
                                    @include font(14px,20px);
                                }
                            }
                            .orig {
                                display: inline-block;
                                vertical-align: middle;
                                margin: 0 8px;
                                @include font(12px,18px);
                                color: #666;
                                text-decoration: line-through;
                                @include mobile {
                                    margin: 0 4px 0 0;
                                    @include font(10px,15px);
                                }
                            }

                            &.sold-out {
                                .orig {
                                    color: #aaa;

                                    @include mobile {
                                        display: block;
                                    }
                                }

                                .salerate {
                                    color: #e984a0;
                                }

                                .sale {
                                    opacity: 0.5;
                                }

                                

                                .salerate {
                                    & + .sale {
                                        margin-right: 8px;
                                    }
                                    @include mobile {
                                        & + .sale {
                                            margin-right: 4px;
                                        }
                                    }
                                }

                                a {
                                    &::after {
                                        content: "일시품절";
                                        display: inline-block;
                                        vertical-align: middle;
                                        padding: 0 8px;
                                        border-radius: 4px;
                                        background-color: #666;
                                        @include font(12px,24px);
                                        color: #fff;

                                        @include mobile {
                                            padding: 0 6px;
                                            @include font(9px,18px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.support {
                margin-top: 40px;
                @include mobile {
                    margin-top: 28px;
                }
                li {
                    position: relative;
                    padding: 32px;
                    @include mobile {
                        padding: 16px;
                    }
                    .sumore_tit {
                        @include font(18px,26px);
                        font-weight: 700;
                        @include textEllipsis;
                        @include mobile {
                            @include font(14px,20px);
                            padding: 0 4px;
                        }
                    }
                    .sumore_cnt {
                        @include font(18px,26px);
                        @include textEllipsisClamp(2);
                        @include mobile {
                            @include font(14px,20px);
                            padding: 0 4px;
                        }
                    }
                    .sumore_cata {
                        @include font(16px,24px);
                        color: #666;
                        @include textEllipsisClamp(2);
                        @include mobile {
                            @include font(11px,16px);
                            padding: 0 4px;
                        }
                    }
                    .sumore_info {
                        margin: 26px 0 10px;
                        overflow: hidden;
                        @include font(14px,20px);
                        color: #666;
                        @include mobile {
                            @include font(10px,15px);
                            margin: 16px 0 8px;
                        }
                        &.sw {
                            margin: 42px 0 10px;
                            @include mobile {
                                margin: 18px 0 8px;
                            }
                        }
                        .info_text{
                            float: left;
                        }
                        .info_date{
                            float: left;
                            position: relative;
                            margin-left: 8px;
                            padding-left: 9px;
                            &::before {
                                content: "";
                                position: absolute;
                                top:7px;
                                left: 0;
                                width: 1px;
                                height: 8px;
                                background-color: #ddd;
                            }
                        }
                    }
                    .link_area {
                        position: absolute;
                        bottom: 32px;
                        right: 32px;
                        display: flex;
                        @include mobile {
                            position: static;
                        }
                        &.sw {
                            a {
                                width: 180px;
                                @include mobile {
                                    width: 100%;
                                }
                            }
                        }
                        a {
                            width: 100px;
                            text-align: center;
                            @include font(14px,38px);
                            border-radius: 40px;
                            border: solid 1px #222;
                            & + a {
                                margin-left: 8px;
                            }
                            @include mobile {
                                width: 100%;
                                @include font(11px,42px);
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-close {
        position: absolute;
        top: 48px;
        @include calc(right,50%,690px + 48px);
        width: 32px;
        height: 32px;
        background: url('/lg5-common/images/icons/btn-close-32.svg') no-repeat 0 0;

        @media screen and (max-width: 1606px) {
            right: 48px;
        }
        @include mobile {
            top: 6px;
            right: 16px;
        }
        &::before {
            content: none;
        }
    }
}

// 통합검색 배너
.customer-support {
    overflow: hidden;
    margin-bottom: 32px;
    padding: 24px 32px;
    border-radius: 16px;
    border: 1px solid #ddd;
    @include mobile {
        margin: 16px;
        padding: 12px 16px;
        border-radius: 8px;
    }
    a {
        display: block;
        @include font(14px,24px);
        padding-right: 20px;
        background: url('/lg5-common/images/icons/icon_help_updown_16.svg') no-repeat right center/16px;
        @include mobile {
            @include font(12px,17px);
            padding-right: 16px;
            background-size: 12px;
        }
        .cs_txt {
            display: inline-block;
            @include font(16px,24px);
            font-weight: 700;
            @include mobile {
                float: none;
                display: block;
                @include font(13px,19px);
                font-weight: 500;
            }
        }
    }
}

// 통합검색 배너
.result-banner {
    &.pc-only {
        .promotion {
            .bnr_area {
                display: table;
                width: 100%;
                margin-bottom: 32px;
                height: 180px;
                border-radius: 16px;
                background: no-repeat right center/cover;
                .bnr_txt {
                    display: table-cell;
                    padding-left: 40px;
                    vertical-align: middle;
                    .txt_b {
                        display: block;
                        // width: 750px;
                        @include font(28px,36px);
                        font-weight: 700;
                        color: #fff;
                        @include textEllipsisClamp(2);
                    }
                    .txt_s {
                        display: block;
                        // width: 750px;
                        margin-top: 8px;
                        @include font(20px,28px);
                        color: #fff;
                        @include textEllipsisClamp(2);
                    }
                }
            }
        }
        .newproduct {
            .bnr_area {
                display: flex;
                margin-bottom: 32px;
                justify-content: space-between;
                align-items: center;
                height: 228px;
                background: no-repeat center/auto 228px;
                .bnr_left {
                    flex: 1;
                    margin-right: 24px;
                    padding-left:32px;
                    .lab_new {
                        display: inline-block;
                        width: 40px;
                        height: 20px;
                        margin-bottom: 8px;
                        background: url(/lg5-common/images/icons/icon_newproduct_bnr_new2.svg) no-repeat center/100%;
                    }
                    .lab_best {
                        display: inline-block;
                        width: 40px;
                        height: 20px;
                        margin-bottom: 8px;
                        background: url(/lg5-common/images/icons/icon_newproduct_bnr_best.svg) no-repeat center/100%;
                    }
                    .txt_b {
                        display: block;
                        @include font(28px,36px);
                        font-weight: 700;
                        @include textEllipsisClamp(2);
                    }
                    .txt_s {
                        display: block;
                        margin-top: 8px;
                        @include font(20px,28px);
                        @include textEllipsisClamp(2);
                    }
                }
                .bnr_right {
                    a {
                        display: block;
                    }
                }
                &.type01 {
                    .bnr_right {
                        position: relative;
                        width: 462px;
                        height: 100%;
                        margin-left: 24px;
                        a {
                            height: 100%;
                            padding: 28px 16px 28px 0;
                        }
                        .prod_box {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            padding: 16px 24px;
                            border-radius: 16px;
                            background-color: #fff;
                            img {
                                width: 140px;
                                height: 140px;
                            }
                            .bnr_pay {
                                padding-left: 16px;
                                .name {
                                    display: block;
                                    @include font(20px,28px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                }
                                .model {
                                    display: block;
                                    @include font(14px,20px);
                                    color: #666;
                                }
                                .pay {
                                    display: block;
                                    margin-top: 8px;
                                    .sale {
                                        display: inline-block;
                                        vertical-align: middle;
                                        @include font(18px,28px);
                                        font-weight: 700;
                                    }
                                    .orig {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: 8px;
                                        @include font(14px,20px);
                                        color: #666;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
                &.type02 {
                    .bnr_right {
                        display: flex;
                        width: 464px;
                        height: 100%;
                        padding: 12px 16px 12px 0;
                        a {
                            & + a {
                                padding-left: 8px;
                            }
                        }
                        .prod_box {
                            position: relative;
                            width: 220px;
                            height: 100%;
                            padding: 8px 8px 16px 8px;
                            border-radius: 16px;
                            background-color: #fff;
                            text-align: center;
                            & + .prod_box {
                                margin-left: 8px;
                            }
                            img {
                                width: 80px;
                                height: 80px;
                            }
                            .bnr_pay {
                                padding-top: 8px;
                                text-align: center;
                                .name {
                                    display: block;
                                    @include font(16px,24px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                }
                                .model {
                                    display: block;
                                    @include font(12px,18px);
                                    color: #666;
                                }
                                .pay {
                                    position: absolute;
                                    bottom: 16px;
                                    left: 0;
                                    display: block;
                                    width: 100%;
                                    margin-top: 8px;
                                    .sale {
                                        display:inline-block;
                                        @include font(14px,20px);
                                        font-weight: 700;
                                        vertical-align: middle;
                                    }
                                    .orig {
                                        display:inline-block;
                                        margin-left: 4px;
                                        @include font(12px,18px);
                                        color: #666;
                                        text-decoration: line-through;
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
                &.nor {
                    background: #fff;
                    .bnr_right {
                        .prod_box {
                            background:none;
                            .img_mask {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 16px;
                                background-color: rgba(0, 0, 0, 0.03);
                            }
                        }
                    }
                }
            }
        }
    }
    &.mo-only {
        .promotion {
            padding: 0 16px;
            .bnr_area {
                display: block;
                width: 100%;
                margin: 20px 0 0;
                height: 86px;
                border-radius: 8px;
                background: no-repeat right center/cover;
                .bnr_txt {
                    display: block;
                    padding:24px 12px;
                    // vertical-align: middle;
                    .txt_b {
                        display: block;
                        @include font(12px,17px);
                        font-weight: 700;
                        color: #fff;
                        @include textEllipsis;
                    }
                    .txt_s {
                        display: block;
                        margin-top: 2px;
                        @include font(11px,16px);
                        color: #fff;
                        @include textEllipsis;
                    }
                }
            }
        }
        .newproduct {
            
            .bnr_area {
                display: block;
                padding: 16px;
                margin-bottom: 12px;
                background: no-repeat right/auto 210px;
                .bnr_left {
                    .lab_new {
                        display: inline-block;
                        width: 27px;
                        height: 14px;
                        margin-bottom: 4px;
                        background: url(/lg5-common/images/icons/icon_newproduct_bnr_new2.svg) no-repeat center/100%;
                    }
                    .lab_best {
                        display: inline-block;
                        width: 27px;
                        height: 14px;
                        margin-bottom: 4px;
                        background: url(/lg5-common/images/icons/icon_newproduct_bnr_best.svg) no-repeat center/100%;
                    }
                    .txt_b {
                        display: block;
                        @include font(20px,26px);
                        font-weight: 700;
                        @include textEllipsis;
                    }
                    .txt_s {
                        display: block;
                        margin-top: 2px;
                        @include font(11px,16px);
                        @include textEllipsis;
                    }
                }
                .bnr_right {
                    a {
                        display: block;
                        height: 100%;
                    }
                }
                &.type01 {
                    .bnr_right {
                        height: 100px;
                        margin-top: 12px;
                        .prod_box {
                            position: relative;
                            display: flex;
                            align-items: flex-start;
                            height: 100%;
                            padding: 5.5px 16px 5.5px 16px;
                            border-radius: 8px;
                            background-color: #fff;
                            img {
                                width: 90px;
                            }
                            .bnr_pay {
                                margin-top: 7.5px;
                                padding-left: 12px;
                                .name {
                                    display: block;
                                    @include font(13px,19px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                }
                                .model {
                                    display: block;
                                    @include font(10px,15px);
                                    color: #666;
                                }
                                .pay {
                                    position: absolute;
                                    bottom: 13px;
                                    display: block;
                                    margin-top: 8px;
                                    .sale {
                                        display: inline-block;
                                        vertical-align: middle;
                                        @include font(12px,17px);
                                        font-weight: 400;
                                    }
                                    .orig {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: 8px;
                                        @include font(10px,15px);
                                        color: #666;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
                &.type02 {
                    .bnr_right {
                        margin: 0 -16px 0 -16px;
                        padding: 12px 0 0 16px;
                        overflow-x: auto;
                        white-space: nowrap;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        a {
                            display: inline-block;
                            width: 272px;
                            & + a {
                                margin:0 12px 0 12px;
                            }
                        }
                        .prod_box {
                            overflow: hidden;
                            display: flex;
                            align-items: flex-start;
                            position: relative;
                            width: 100%;
                            margin-right: 8px;
                            padding: 6px 8px;
                            border-radius: 8px;
                            background-color: #fff;
                            img {
                                width: 90px;
                                height: 90px;
                            }
                            .bnr_pay {
                                margin-top: 7px;
                                padding-left: 12px;
                                .name {
                                    display: block;
                                    @include font(13px,19px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                    white-space: normal;
                                }
                                .model {
                                    display: block;
                                    @include font(10px,15px);
                                    color: #666;
                                }
                                .pay {
                                    position: absolute;
                                    bottom: 13px;
                                    .sale {
                                        display: inline-block;
                                        vertical-align: middle;
                                        @include font(12px,17px);
                                        font-weight: 400;
                                    }
                                    .orig {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: 4px;
                                        @include font(10px,15px);
                                        color: #666;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
                &.nor {
                    height: auto;
                    padding: 0;
                    background: #fff;
                    .bnr_left {
                        margin: 12px 16px 0;
                    }
                    .bnr_right {
                        margin-top: 12px;
                        height: auto;
                        .prod_box {
                            align-items: flex-start;
                            width: 100%;
                            padding:0 0 0 16px;
                            border-radius: 0;
                            img {
                                width: 113px;
                                height: 113px;
                            }
                            .bnr_pay {
                                margin-top: 12px;
                                width: 100%;
                                padding-left: 0;
                                .name {
                                    padding:0 12px 0 12px;
                                }
                                .model {
                                    padding-left: 12px;
                                }
                                .pay {
                                    position: static;
                                    height: 28px;
                                    padding-top: 2px;
                                    padding-left: 12px;
                                    background-color: #f4f4f4;
                                }
                            }
                        }
                    }
                    &.type01 {
                        .bnr_right {
                            margin: 12px 0 0 0;
                            padding: 0 16px;
                            a {
                                width: 100%;
                                & + a {
                                    margin:0 12px 0 12px;
                                }
                            }
                            .prod_box {
                                overflow: hidden;
                                align-items: center;
                                width: 100%;
                                padding: 6px 8px;
                                background: none;
                                border-radius: 8px;
                                img {
                                    width: 105px;
                                    height: 105px;
                                }
                                .img_mask {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 8px;
                                    background-color: rgba(0, 0, 0, 0.03);
                                }
                                .bnr_pay {
                                    margin-top: 0;
                                    padding-left: 12px;
                                    .name {
                                        padding-left: 0;
                                    }
                                    .model {
                                        padding-left: 0;
                                    }
                                    .pay {
                                        position: static;
                                        padding-left: 0;
                                        height: auto;
                                        margin-top: 8px;
                                        background-color: initial;
                                    }
                                }
                            }
                        }
                    }
                    &.type02 {
                        .bnr_right {
                            margin: 12px 0 0 0;
                            padding-left: 16px;
                            a {
                                width: 287px;
                                & + a {
                                    margin:0 12px 0 12px;
                                }
                            }
                            .prod_box {
                                overflow: hidden;
                                align-items: center;
                                width: 100%;
                                padding: 6px 8px;
                                background: none;
                                border-radius: 8px;
                                img {
                                    width: 105px;
                                    height: 105px;
                                }
                                .img_mask {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 8px;
                                    background-color: rgba(0, 0, 0, 0.03);
                                }
                                .bnr_pay {
                                    margin-top: 0;
                                    padding-left: 12px;
                                    .name {
                                        padding-left: 0;
                                    }
                                    .model {
                                        padding-left: 0;
                                    }
                                    .pay {
                                        position: static;
                                        padding-left: 0;
                                        height: auto;
                                        margin-top: 8px;
                                        background-color: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .promotion + .newproduct {
            margin-top: 20px;
        }
    }
}

// 총수량
.alllist-count {
    padding-bottom: 28px;
    @include font(14px,20px);
    font-weight: 700;
    @include mobile {
        padding: 0 16px 24px 16px;
        @include font(13px,19px);
    }
}

// 통합검색 결과
.result-list-wrap {
    margin-top: -100px;
    @include mobile {
        margin-top: -40px;
    }
    // BTOCSITE-23248 list-no-data css 추가
    &.list-no-data{
        margin-top: 0;
        @include mobile {
            margin-top: 0;
        }
    }
    .result-total-count {
        margin: 8px 0 24px 0;
        @include font(14px,20px);
        color: #000;
        @include mobile{
            margin: 12px 0 24px 0;
            @include font(13px,19px);
        }
    }
    .list-wrap {
        margin: 120px 0;
        @include mobile {
            margin: 80px 0 0;

            &:first-child {
                margin-top: 60px;
            }
        }

        &.event {
            @include mobile {
                margin-left: -16px;
                margin-right: -16px;
                .list-sorting {
                    &.insort {
                        padding-left: 16px;
                    }
                }
                .result-list {
                    &.boxarea {
                        padding-left: 16px;
                    }
                }
            }
        }
        &.support {
            .list-sorting {
                .sort-area {
                    .sort-tit {
                        font-weight: 700 !important;
                        @include mobile {
                            @include font(18px,24px);
                        }
                    }
                }
            }
        }
        &.story {
            .result-list {
                display: flex;
                flex-wrap: wrap;
                margin: -24px -12px 0;
                
                @include mobile {
                    margin: -10px -6px 0;
                }
                li {
                    width: 25%;
                    margin: 24px 0 0;
                    padding: 0 12px;

                    @include tablet {
                        width: 50%;
                    }
                    
                    @include mobile {
                        width: 50%;
                        margin-top: 10px;
                        padding: 0 6px;
                        border-radius: 8px;
                    }
                    a {
                        display: block;
                    }
                }
            }
            .story-img-area {
                position: relative;
                .ico-movie {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    z-index: 5;
                    width: 24px;
                    height: 24px;
                    background: url(/lg5-common/images/icons/ico_video.svg) no-repeat 50% 50% / contain;

                    @include mobile {
                        top: 8px;
                        right: 8px;
                    }
                }
                .story-img {
                    position: relative;
                    display: block;
                    height: 294px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    @include tablet {
                        height: 48.194vw;
                    }
                }
            }
            .story-cnt {
                padding: 16px 20px 20px;
                .story-tag {
                    color: #008182;
                    @include font(14px,20px);
                }
                .story-tit {
                    height: 54px;
                    margin-top: 4px;
                    color: #000;
                    font-weight: 700;
                    @include font(18px,26px);
                    @include textEllipsisClamp(2);
                }

                @include mobile {
                    padding: 8px 12px 12px;
                    .story-tag {
                        @include font(10px,15px);
                    }
                    .story-tit {
                        height: 44px;
                        margin-top: 0;
                        font-weight: 500;
                        @include font(15px,21px);
                    }
                }
            }
        }
        .result-list {
            &.boxarea {
                & + .result-allview_area {
                    .result-allview {
                        &.fixed {
                            display: none !important;
                        }
                    }
                }
            }
        }
        // 리스트별 정렬
        .list-sorting {
            &.insort {
                display: flex;
                justify-content: space-between;
                padding: 0 0 24px;
                @include mobile {
                    padding: 0 0 16px;
                }
                .sort-area {
                    margin-top: 0;
                    @include mobile {
                        display: block;
                    }
                    &.left {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        margin-top: 0;
                        padding-top: 0;
                    }
                    .sort-tit {
                        display: block;
                        @include font(24px,32px);
                        font-weight: 500;
                        @include mobile {
                            @include font(18px,24px);
                            font-weight: $font-weight-bold; 
                        }
                        em {
                            margin-left: 8px;
                            font-weight: 400;
                            color: #ea1917;
                            @include mobile {
                                margin-left: 4px;
                            }
                        }
                    }
                    .sort_tomorrow {
                        margin-left: 16px;
                        &.sort_multi_product, //BTOCSITE-48682 다품목할인 추가
                        &.sort_executive {
                            margin-left: 16px;
                        }
                        @include mobile {
                            display: none;
                            margin-left: 10.5px;

                            &.sort_multi_product, //BTOCSITE-48682 다품목할인 추가
                            &.sort_executive {
                                margin-left: 10.5px;
                            }
                        }
                        & + .sort_tomorrow.sort_multi_product, //BTOCSITE-48682 다품목할인 추가
                        & + .sort_tomorrow.sort_executive {
                            margin-left: 20px;
                        }

                        .chk-wrap {
                            input {
                                & + label {
                                    @include font(12px,24px);
                                    &::after {
                                        background-image: url(/lg5-common/images/icons/btn-checkbox-off-24-black.svg);
                                    }
                                }
                                &:checked + label {
                                    @include font(12px,24px);
                                    font-weight: 700;
                                    &::after {
                                        top: 0;
                                        background-image: url(/lg5-common/images/icons/btn-checkbox-on-24-black.svg);
                                    }
                                }
                                &:disabled + label {
                                    @include font(12px,24px);
                                    color: #666;
                                    &::after {
                                        top: 0;
                                        background-image: url(/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg);
                                    }
                                }
                            }
                            label { // BTOCSITE-32032 내일 배송 체크박스 디자인 수정
                                padding-left: 32px;
                                padding-right: 26px;
                                color: #000;
                                font-weight: 700;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(calc(-50% + 1px));
                                    width: 32px;
                                    height: 32px;
                                    background: url(/lg5-common/images/icons/ico_truck_32.svg) no-repeat 50% 50%;
                                }
                                &::after {
                                    left: auto;
                                    right: 0;
                                    background-size: 20px;
                                }
                            }
                        }

                        &.sort_multi_product, //BTOCSITE-48682 다품목할인 추가
                        &.sort_executive {
                            .chk-wrap {
                                label {
                                    padding-left: 0;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .sort_curation {
                        flex: 1;
                        @include textEllipsisClamp(1);
                        padding: 0 16px;
                        @include font(20px,28px);
                        @include mobile {
                            padding: 0 8px;
                            @include font(15px,21px);
                        }
                    }
                    .sort-list {
                        .sort-select-wrap {
                            .ui-selectbox-wrap {
                                .ui-selectbox-view {
                                    .ui-select-button {
                                        @include mobile {
                                            padding-right: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // 차세대 가전구독
        &.subspackage {
            .result-list {
                & > li {
                    .badge_box {
                        .prod_badge {
                            background: #f4f4f4;
                        }
                    }

                    .result-tit {
                        margin-bottom: 8px;
                        .add_tit {
                            display: block;
                            margin: 3px 0 0 0;
                            color: #666;
                            @include font(12px, 18px);
                        }
                    }

                    .result-info {
                        a {
                            display: block;
                            margin-top: 44px;
                        }
                        .info-text {
                            padding-top: 0;
                        }

                        .info-price {
                            margin-top: 8px;
                            .price-info {
                                display: inline-block;
                            }
                        }

                        .price-info {
                            .price {
                                display: inline-block;
                            }
                        }
                    }

                    .result-tabs {
                        .tabs-contant {
                            padding-top: 34px;

                            dl {
                                display: block;

                                & + dl {
                                    margin-top: 16px;
                                }

                                .colorchip-tit {
                                    line-height: 36px;
                                }
                            }

                            .care-service-info {
                                dt, dd {
                                    float: none;
                                }
                                dd {
                                    position: relative;
                                    margin: 16px 0 0 0;
                                    padding-left: 7px;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 9px;
                                        left: 0;
                                        width: 3px;
                                        height: 3px;
                                        background: #000;
                                        border-radius: 1.5px;
                                    }
                                }
                            }
                        }
                    } 
                }
            }

            .colorchip-info {
                @include tablet {
                    margin-right: 0;
                }
                dd {
                    width: calc(100% - 34px);
                }
            }

            .colorchip-wrap {
                font-size: 0;
                line-height: 1;
                span {
                    display: inline-block;
                    position: relative;
                    width: 36px;
                    height: 36px;
                    margin-right: 4px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 24px;
                        height: 24px;
                        border-radius: 9px 9px;
                        transform: translate(-50%, -50%);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 25px;
                        height: 25px;
                        border: 1px solid rgba(0,0,0,.12);
                        transform: translate(-50%, -50%);
                        border-radius: 9px 9px;
                    }
                }
            }

            @include mobile {
                .list-sorting {
                    .insort {
                        padding-bottom: 20px;
                    }
                }

                .result-list {
                    & > li {
                        .result-tit {
                            margin-bottom: 0;
                            .add_tit {
                                display: block;
                                margin: 6px 0 0 0;
                                @include font(10px, 15px);
                            }
                        }
    
                        .result-info {
                            a {
                                margin-top: 0;
                            }
                            .info-price {
                                .price-info {
                                    text-align: left;
                                }
                            }
                        }
    
                        .result-tabs {
                            .tabs-contant {
                                padding: 12px 16px 16px;
    
                                dl {
                                    display: block;
    
                                    & + dl {
                                        margin-top: 8px;
                                    }
    
                                    .colorchip-tit {
                                        line-height: 30px;
                                    }
                                }
    
                                .care-service-info {
                                    dt, dd {
                                        float: none;
                                    }
                                    dt {
                                        width: 100%;
                                    }
                                    dd {
                                        width: 100%;
                                        margin: 7px 0 0 0;
                                        padding-left: 5px;
    
                                        &::before {
                                            top: 9px;
                                            width: 2px;
                                            height: 2px;
                                            border-radius: 1px;
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
    
                .colorchip-wrap {
                    max-width: 100%;
                    margin: -4px 0 0 -8px;
                    span {
                        display: inline-block;
                        position: relative;
                        width: 30px;
                        height: 30px;
                        margin: 4px 0 0 8px;
                    }
                }
            }
        }
        // 전체보기 링크
        .result-allview_area {
            margin-top: 40px;
            @include mobile {
                margin-top: 20px;
            }
            .result-allview {
                text-align: center;
                z-index: 10;
                font-weight: $font-weight-medium;
                em {
                    margin-left: 4px;
                }
                a {
                    position: relative;
                    display: inline-block;
                    padding: 14px 32px;
                    @include font(16px,20px);
                    border: 1px solid $color-default;
                    border-radius: 26px;
                    > em{
                        padding-right: 20px;
                        background: url("/lg5-common/images/icons/btn-arr-16-down.svg") no-repeat right center/14px;
                    }
                    @include mobile {
                        padding:8px 18px 9px;
                        @include font(13px,19px);
                        > em{
                            padding-right: 16px;
                            background-size: 12px;
                        }
                    }
                }
                &.fixed {
                    position: fixed;
                    left: calc(50% + 39px);
                    bottom: 55px;
                    //하단 그라이데이션 바
                    &::before {
                        content: "";
                        position:fixed;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 156px;
                        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
                        @include mobile {
                            height: 125px;
                        }
                    }
                    @include tablet {
                        left: calc(50% - 70px);
                        bottom: 60px;
                    }
                    a {
                        display: inline-block;
                        width: 156px;
                        padding: 0;
                        border-radius: 40px;
                        background: #222;
                        @include font(16px,50px);
                        color: #fff;
                        box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.14);
                        @include mobile {
                            width: 140px;
                            box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.14);
                            @include font(13px,38px);
                        }
                    }
                    &.outside {
                        &::before {
                            content: none;
                        }
                        a {
                            display: none;
                        }
                    }
                }
                &.static {
                    position: static;
                    a {
                        display: inline-block;
                        width: 156px;
                        padding: 0;
                        border-radius: 40px;
                        background: #222;
                        @include font(16px,50px);
                        color: #fff;
                        @include mobile {
                            width: 140px;
                            @include font(13px,38px);
                        }
                    }
                }
            }
        }
        // 큐레이션
        .curation-area {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 40px 0 40px 40px;
            @include mobile {
                display: block;
                padding: 20px 0;
            }
            .cura_title {
                display: block;
                .tit_s {
                    @include font(20px,28px);
                    font-weight: 400;
                    @include mobile {
                        @include font(13px,19px);
                    }
                }
                .tit_b {
                    margin-top: 8px;
                    @include font(36px,44px);
                    font-weight: 700;
                    @include mobile {
                        @include font(22px,22px);
                    }
                }
            }
            .cura_itemlink {
                ul {
                    @include mobile {
                        margin-top: 16px;
                    }
                    li {
                        margin-top: 29px;
                        @include mobile {
                            margin-top: 14px;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                        a {
                            position: relative;
                            display: inline-block;
                            @include font(24px,32px);
                            @include mobile {
                                @include font(15px,21px);
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 16px;
                                background-color: #33cc32;
                                opacity: 0.6;
                                z-index: -1;
                                @include mobile {
                                    height: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .result-list {
            .item {
                min-height: auto;
                //소모품
                &.supplies {
                    display: flex;
                    // align-items: flex-start;
                    align-items: center; // BTOCSITE-22674 소모품 이미지 중앙 정렬
                    position: relative;
                    @include mobile {
                        padding: 20px 16px;
                        min-height: auto;
                    }
                    .supplies-thumb {
                        width: 116px;
                        z-index: 1;
                        @include mobile {
                            width: 88px;
                        }
                        a {
                            display: block;
                            text-align: center; // BTOCSITE-22674 소모품 이미지 중앙 정렬
                        }
                        img {
                            max-width: 100%;
                            vertical-align: top;
                        }
                    }
                    .supplies-info {
                        flex: 1;
                        position: relative;
                        padding:0 0 0 38px;
                        @include mobile {
                            padding-left: 16px;
                        }
                        .supplies-text {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            @include mobile {
                                align-items: flex-start;
                            }

                            .supplies-prod-info {
                                @include mobile {
                                    width: calc(100% - 50px);
                                }

                                &.sold-out {
                                    @include mobile {
                                        width: 100%;
                                    }
                                }
                                
                            }

                            .result-cata {
                                @include font(14px,20px);
                                @include mobile {
                                    @include font(10px,15px);
                                }
                            }
                            .result-tit {
                                margin-bottom: 0;
                                @include font(18px,26px);
                                font-weight: 700;
                                @include mobile {
                                    @include font(13px,19px);
                                }
                            }
                            .result-model {
                                margin-top: 8px;
                                @include font(12px,18px);
                                color: #666;
                                @include mobile {
                                    margin-top: 0;
                                    @include font(10px,15px);
                                }
                            }
                        }
                        .supplies-price {
                            &:after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                            @include mobile {
                                margin-top: 4px;

                                &.sold_out {
                                    margin-top: 7px;
                                }
                            }
                            a {
                                float: right;
                                display: inline-flex;
                                align-items: center;
                                justify-content: end;
                                flex-wrap: wrap;
                                margin-top: 8px;
                                @include mobile {
                                    justify-content: start;
                                }

                                @include mobile {
                                    float: none;
                                    display: flex;
                                    margin-top: 0;
                                }
                            }
                            .btn-shared-model {
                                float: left;
                                justify-content: start;
                                margin-top: 12px;
                                padding-right: 16px;
                                color: #000;
                                @include font(14px, 18px);
                                background: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg) no-repeat right center / 16px;

                                @include mobile {
                                    float: none;
                                    display: inline-block;
                                    margin-top: 12px;
                                    padding-right: 14px;
                                    background-size: 12px; 
                                    @include font(12px, 15px);
                                }
                            }


                            .salerate {
                                display: inline-block;
                                vertical-align: middle;
                                @include font(16px,24px);
                                color: #ea1917;
                                @include mobile {
                                    @include font(15px,21px);
                                }
                            }
                            .price-in {
                                display: inline-block;
                                margin-left: 8px;
                                @include font(16px,24px);
                                font-weight: 700;
                                @include mobile {
                                    margin: 0 4px;
                                    @include font(15px,21px);
                                }
                            }
                            .original {
                                display: inline-block;
                                margin: 0 8px;
                                @include font(12px,18px);
                                color: #666;
                                text-decoration: line-through;
                                @include mobile {
                                    margin: 0 4px 0 0;
                                    @include font(11px,15px);
                                }
                            }
                            &.sold_out {
                                a {
                                    position: relative;
                                    padding-right: 76px;

                                    @include mobile {
                                        display: block;
                                        padding-right: 0;
                                    }
                                }
                                .salerate {
                                    opacity: 0.5; // BTOCSITE-25479 일시 품절 시 가격정보 투명화
                                    color: rgba(234, 25, 23, 0.5);
                                    @include pc {
                                    }
                                    @include mobile {
                                        & + .price-in {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                                .price-in {
                                    opacity: 0.5; // BTOCSITE-25479 일시 품절 시 가격정보 투명화
                                    color: #666;
                                }
                                .orig_pay {
                                    opacity: 0.5; // BTOCSITE-25479 일시 품절 시 가격정보 투명화
                                    color: #aaa;
                                    @include mobile {
                                        margin-right: 8px;
                                    }
                                }
                                .original {
                                    opacity: 0.5; // BTOCSITE-25479 일시 품절 시 가격정보 투명화
                                    @include mobile {
                                        display: block;
                                        margin-right: 0;
                                    }
                                }
                                a {
                                    &::before {
                                        content: "일시품절";
                                        display: inline-block;
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        min-width: 68px;
                                        //margin-right: 16px;
                                        padding: 0 8px;
                                        border-radius: 4px;
                                        background-color: #666;
                                        @include font(14px,26px);
                                        color: #fff;
                                        transform: translateY(-50%);
                                        @include mobile {
                                            content: none;
                                        }
                                    }
                                    &::after {
                                        content: "일시품절";
                                        display: inline-block;
                                        vertical-align: middle;
                                        padding: 0 6px;
                                        border-radius: 4px;
                                        background-color: #666;
                                        @include font(11px,20px);
                                        color: #fff;
                                        @include pc {
                                            content: none;
                                        }
                                    }
                                }

                                .btn-shared-model {
                                    padding-right: 16px;

                                    &::before {
                                        display: none;
                                    }

                                    @include mobile {
                                        display: inline-block;
                                        padding-right: 14px;
                                        background-position: 100% calc(50% - 1px);
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .btn-cart {
                            display: block;
                            position: relative;
                            z-index: 1;
                            width: 40px;
                            height: 40px;
                            background: #fff url(/lg5-common/images/icons/ico_cart_48.svg) no-repeat 50% 50%;
                            background-size: 24px 24px;
                            border: 1px solid #ddd;
                            border-radius: 8px;
                        }
                    }
                }
                //문제해결
                &.solution {
                    position: relative;
                    border: 0;
                    border-top: 1px solid #ddd;
                    border-radius: 0;
                    box-shadow: none;
                    min-height: 178px;
                    padding: 24px 0;
                    @include mobile {
                        padding: 16px 0 16px 0;
                        min-height: auto;
                    }
                    .solution_tit {
                        padding-right: 208px;
                        @include font(20px,28px);
                        font-weight: 700;
                        @include textEllipsisClamp(1);
                        @include mobile {
                            width: 100%;
                            margin-bottom: 8px;
                            padding-right: 0;
                            @include font(16px,22px);
                            @include textEllipsisClamp(2);
                        }
                    }
                    .solution_flexarea {
                        @include mobile {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .solution_cnt {
                            margin-top: 4px;
                            padding-right: 244px;
                            
                            @include mobile {
                                flex: 1;
                                padding-right: 12px;
                            }
                            .cnt_txt {
                                display: block;
                                @include font(20px,28px);
                                @include textEllipsisClamp(2);
                                @include mobile {
                                    @include font(14px,20px);
                                    @include textEllipsisClamp(4);
                                }
                            }
                            .solution_tag {
                                position: absolute;
                                left: 0;
                                bottom: 24px;
                                width: 100%;
                                @include mobile {
                                    position: static;
                                    margin-top: 8px;
                                    width: 100%;
                                }
                                .tag_itm {
                                    @include font(20px,28px);
                                    color: #666;
                                    @include mobile {
                                        @include font(13px,19px);
                                    }
                                }
                            }
                        }
                        .solution_imgwrap {
                            position: absolute;
                            top: 24px;
                            right: 0;
                            width: 212px;
                            height: 130px;
                            @include mobile {
                                position: static;
                                width: 128.5px;
                                height: 80px;
                            }
                            .solution_img {
                                position: relative;
                                overflow: hidden;
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: center;
                                width: 100%;
                                height: 100%;
                                border-radius: 12px;
                                @include mobile {
                                    border-radius: 4px;
                                }
                                &::before {
                                    content:'';
                                    position:absolute;
                                    left:0;
                                    top:0;
                                    width:100%;
                                    height:100%;
                                    background:#000;
                                    opacity:0.3;
                                    z-index:1;
                                    border-radius: 12px;
                                    @include mobile {
                                        border-radius: 4px;
                                    }
                                }
                                &::after {
                                    content: "";
                                    position: absolute;
                                    right: 12px;
                                    bottom: 12px;
                                    width: 24px;
                                    height: 24px;
                                    background: url("/lg5-common/images/icons/ico_video_wt.svg") no-repeat 50% 50% / contain;
                                    z-index:1;
                                    @include mobile {
                                        right: 4px;
                                        bottom: 4px;
                                    }
                                }
                            }
                        }
                    }
                    &.no_video {
                        .solution_flexarea {
                            .solution_cnt {
                                padding-right: 0;
                            }
                            .solution_imgwrap {
                                display: none;
                            }
                        }
                    }
                }
                // 센터/매장
                &.shop {
                    min-height: 146px;
                    padding: 28px 32px;
                    @include mobile {
                        padding: 20px;
                    }
                    .shop_head {
                        @include mobile {
                            position: relative;
                            width: 100%;
                        }
                        .state {
                            position: relative;
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 8px;
                            padding:0 9px 0 22px;
                            @include font(14px,20px);
                            font-weight: 700;
                            background: no-repeat left center/20px;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 4px;
                                right: 0;
                                width: 1px;
                                height: 12px;
                                background-color: #ddd;
                            }
                            @include mobile {
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding:0 0 0 16px;
                                margin: 0;
                                @include font(11px,16px);
                                background: no-repeat left center/16px;
                                &::before {
                                    content: none;
                                }
                            }
                            &.smooth {
                                color: #198301;
                                background-image: url("/lg5-common/images/icons/icon-shopstate-20-smooth.svg");
                            }
                            &.normal {
                                color: #0072de;
                                background-image: url("/lg5-common/images/icons/icon-shopstate-20-normal.svg");
                            }
                            &.busy {
                                color: #a50024;
                                background-image: url("/lg5-common/images/icons/icon-shopstate-20-busy.svg");
                            }
                            &.onduty {
                                margin-right: 8px;
                                padding:0 9px 0 0;
                                color: #000;
                                @include mobile {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                            &.close {
                                margin-right: 8px;
                                padding:0 9px 0 0;
                                color: #666;
                                @include mobile {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                            &.shop {
                                margin-right: 8px;
                                padding:0 9px 0 0;
                                color: #666;
                                @include mobile {
                                    margin: 0;
                                    padding: 0;
                                    top: 1px;
                                    left: 0;
                                    right: auto;
                                    @include font(14px,20px);
                                    font-weight: 400;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        top: calc(50% - 4px);
                                        right: -5px;
                                        width: 1px;
                                        height: 8px;
                                        background-color: #ddd;
                                    }
                                    & + .name {
                                        padding-left: 35px;
                                    }
                                }
                            }
                        }
                        .name {
                            position: relative;
                            display: inline-block;
                            vertical-align: middle;
                            @include font(18px,26px);
                            font-weight: 700;
                            @include mobile {
                                margin-left: 0;
                                padding-left: 0;
                                @include font(16px,22px);
                            }
                        }
                        .lab_event {
                            display: inline-block;
                            width: 46px;
                            height: 20px;
                            margin-left: 12px;
                            background: url("/lg5-common/images/icons/ico-event-46x20.svg") no-repeat left center/100%;
                            font-size: 0;
                            color: transparent;
                            vertical-align: middle;
                            @include mobile {
                                width: 39px;
                                height: 17px;
                                margin-left: 8px;
                            }
                        }
                    }
                    .shop_address {
                        margin-top: 20px;
                        padding-right: 380px;
                        @include font(14px,20px);
                        @include mobile {
                            margin-top: 8px;
                            padding-right: 0;
                            @include font(15px,21px);
                        }
                    }
                    .shop_time {
                        margin-top: 4px;
                        @include font(14px,20px);
                        color: #666;
                        @include mobile {
                            margin-top: 8px;
                            @include font(14px,20px);
                        }
                    }
                    .result-btn-area {
                        position: absolute;
                        bottom: 28px;
                        right: 32px;
                        @include mobile {
                            margin-top: 16px;
                            position: static;
                        }
                    }
                    &.bestshop {
                        .result-btn-area {
                            .result-btn {
                                &.black {
                                    //BTOCISTE-25506 센터/매장 영역 매장상담예약 버튼 컬러 변경
                                    // background-color: #b00b69;
                                    // border-color: #b00b69;
                                    background-color: #ea1917;
                                    border-color: #ea1917;
                                }
                            }
                        }
                    }
                }
                // Live LG
                &.livelg {
                    position: relative;
                    border: 0;
                    border-top: 1px solid #ddd;
                    border-radius: 0;
                    box-shadow: none;
                    min-height: 178px;
                    padding: 24px 0;
                    @include mobile {
                        padding: 16px 0 16px 0;
                        min-height: auto;
                    }
                    .livelg_tit {
                        padding-right: 208px;
                        @include font(20px,28px);
                        font-weight: 700;
                        @include textEllipsisClamp(1);
                        @include mobile {
                            width: 100%;
                            margin-bottom: 8px;
                            padding-right: 0;
                            @include font(16px,22px);
                            @include textEllipsisClamp(2);
                        }
                    }
                    .livelg_flexarea {
                        @include mobile {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .livelg_cnt {
                            margin-top: 4px;
                            padding-right: 208px;
                            @include mobile {
                                flex: 1;
                                padding-right: 12px;
                            }
                            .cnt_txt {
                                display: block;
                                @include font(20px,28px);
                                @include textEllipsisClamp(2);
                                @include mobile {
                                    @include font(14px,20px);
                                    @include textEllipsisClamp(4);
                                }
                            }
                            .livelg_tag {
                                position: absolute;
                                left: 0;
                                bottom: 24px;
                                width: 100%;
                                overflow: hidden;
                                @include mobile {
                                    position: static;
                                    margin-top: 8px;
                                    width: 100%;
                                }
                                .tag_logo {
                                    position: relative;
                                    float: left;
                                    width: 84px;
                                    height: 26px;
                                    padding-right: 20px;
                                    background: url("/lg5-common/images/icons/ico-livelg-72x20.png") no-repeat left center/64px 16px;
                                    @include mobile {
                                        width: 61px;
                                        height: 19px;
                                        background-size: 45px 11px;
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 5px;
                                        right: 0;
                                        width: 16px;
                                        height: 16px;
                                        background: url("/lg5-common/images/icons/btn-outlink-28.svg") no-repeat center/100%;
                                        @include mobile {
                                            top: 1px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                                .tag_itm {
                                    position: relative;
                                    float: left;
                                    margin-left: 8px;
                                    padding-left: 9px;
                                    @include font(18px,26px);
                                    color: #008182;
                                    @include mobile {
                                        @include font(13px,19px);
                                        margin-left: 4px;
                                        padding-left: 5px;
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 8px;
                                        left: 0;
                                        width: 1px;
                                        height: 8px;
                                        background-color: #ddd;
                                        @include mobile {
                                            top: 6px;
                                        }
                                    }
                                }
                            }
                        }
                        .livelg_imgwrap {
                            position: absolute;
                            top: 24px;
                            right: 0;
                            width: 176px;
                            height: 130px;
                            @include mobile {
                                position: static;
                                width: 122px;
                                height: 90px;
                                border-radius: 4px;
                            }
                            .livelg_img {
                                overflow: hidden;
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: center;
                                background-image: url(/lg5-common/images/UTS/lg_logo.jpg);
                                width: 100%;
                                height: 100%;
                                border: solid 1.5px #ddd;
                                border-radius: 12px;
                                @include mobile {
                                    border-radius: 4px;
                                }
                                img {
                                    width: 100%;
                                    height: 100%;
                                    max-width: 100%;
                                    max-height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
                // 스토리
                &.story {
                    overflow: hidden;
                    display: block;
                    position: relative;
                    min-height: initial;
                    padding: 0;
                    border: 0;
                }
                // 회사소개
                &.company {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-height: auto;
                    padding: 34px 32px;
                    @include mobile {
                        display: block;
                        position: relative;
                        padding: 16px 16px 40px 16px;
                    }
                    .company_tit {
                        width: 296px;
                        .company_name {
                            @include font(18px,26px);
                            font-weight: 700;
                            @include textEllipsis;
                            @include mobile {
                                @include font(16px,22px);
                            }
                        }
                        .company_cata {
                            margin-top: 4px;
                            @include font(14px,20px);
                            color: #666;
                            @include mobile {
                                position: absolute;
                                bottom: 16px;
                                left: 16px;
                                margin-top: 0;
                                @include font(12px,17px);
                            }
                            em {
                                font-weight: 700;
                                color: #000;
                            }

                        }
                    }
                    .company_cnt {
                        flex: 1;
                        // width: calc(100% - 406px);
                        margin-left:24px;
                        word-break: keep-all;
                        @include mobile {
                            margin-left: 0;
                        }
                        .cnt_txt {
                            margin-top: 8px;
                            @include font(18px,26px);
                            @include textEllipsisClamp(2);
                            @include mobile {
                                @include font(14px,20px);
                            }
                        }
                    }
                }
            }
            &.boxarea {
                margin-left: -22px;
                @include pc {
                    height: 390px;
                    overflow: hidden;
                    padding-right: 10px;
                    margin-right: -10px;
                    &.more {
                        height: auto;
                        overflow: initial;
                        padding-right: 0;
                        margin-right: 0;
                    }
                }
                &::after {
                    content: "";
                    display: table;
                    clear: both;
                }
                @include mobile {
                    display: block;
                    margin-left: 0;
                    padding-left: 4px;
                    white-space: nowrap;
                    overflow-x: auto;
                    -ms-overflow-style: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                li {
                    float: left;
                    margin-bottom: 22px;
                    padding-left: 22px;
                    width: 33.33%;
                    @include mobile {
                        width: 180px;
                        float: none;
                        display: inline-block;
                        margin-bottom: 8px;
                        padding-left: 0;
                        padding-right: 12px;
                        vertical-align: middle;
                    }
                    .box {
                        border-radius: 16px;
                        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
                        background-color: #fff;
                        overflow: hidden;
                        @include mobile {
                            border-radius: 8px;
                            box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.14);
                        }
                        &.event {
                            position: relative;
                            height: 370px;
                            @include mobile {
                                height: 231px;
                            }
                            .box_bgimg {
                                position: relative;
                                width: auto;
                                height: 202px;
                                overflow: hidden;
                                img {
                                    position: absolute;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    width: 100%;
                                }
                                @include mobile {
                                    height: 135px;
                                    img {
                                        top: auto;
                                        left: 50%;
                                        transform: translate(-50%,0);
                                        height: 100%;
                                    }
                                }
                            }
                            .box_cnt {
                                position: relative;
                                height: 168px;
                                padding: 16px 20px 20px 20px;
                                @include mobile {
                                    height: 96px;
                                    padding: 8px 12px 12px 12px;
                                    @include font(10px,15px);
                                }
                                .box_lab {
                                    position: absolute;
                                    top: 16px;
                                    left: 20px;
                                    overflow: hidden;
                                    height: 20px;
                                    @include font(14px,20px);
                                    color: #666;
                                    @include mobile {
                                        top: 8px;
                                        left: 12px;
                                        height: 15px;
                                        @include font(10px,15px);
                                    }
                                    li {
                                        position: relative;
                                        float: left;
                                        width: auto;
                                        margin:0 0 0 8px;
                                        padding-left: 9px;
                                        @include mobile {
                                            margin:0 0 0 4px;
                                            padding:0 0 0 5px;
                                        }
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            top: calc(50% - 4px);
                                            left: 0;
                                            width: 1px;
                                            height: 8px;
                                            background-color: #ddd;
                                        }
                                        &:first-child {
                                            margin-left: 0;
                                            padding-left: 0;
                                            &::before {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                                .box_name {
                                    margin-top: 24px;
                                    @include font(18px,26px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                    @include mobile {
                                        margin-top: 15px;
                                        @include font(15px,21px);
                                        white-space: normal;
                                    }
                                }
                                .box_date {
                                    position: absolute;
                                    bottom: 20px;
                                    left: 20px;
                                    @include font(14px,20px);
                                    color: #666;
                                    @include mobile {
                                        bottom: 12px;
                                        left: 12px;
                                        @include font(10px,15px);
                                    }
                                }
                            }
                        }
                        &.naverblog {
                            position: relative;
                            height: 370px;
                            @include mobile {
                                width: 295px;
                                height: 118px;
                            }
                            .box_bgimg {
                                width: auto;
                                height: 202px;
                                img {
                                    height: 100%;
                                }
                                @include mobile {
                                    position: absolute;
                                    top: 16px;
                                    right: 16px;
                                    width: 86px;
                                    height: 86px;
                                    border-radius: 4px;
                                    overflow: hidden;
                                }
                            }
                            .box_cnt {
                                padding: 20px;
                                @include mobile {
                                    padding: 12px;
                                }
                                .box_lab {
                                    overflow: hidden;
                                    @include font(14px,20px);
                                    color: #666;
                                    @include mobile {
                                        @include font(10px,15px);
                                    }
                                    li {
                                        position: relative;
                                        float: left;
                                        width: auto;
                                        margin:0 0 0 8px;
                                        padding-left: 9px;
                                        @include mobile {
                                            margin:0 0 0 4px;
                                            padding:0 0 0 4px;
                                        }
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            top: 7px;
                                            left: 0;
                                            width: 1px;
                                            height: 8px;
                                            background-color: #ddd;
                                        }
                                        &:first-child {
                                            margin-left: 0;
                                            padding-left: 0;
                                            &::before {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                                .box_name {
                                    margin-top: 4px;
                                    @include font(18px,26px);
                                    font-weight: 700;
                                    @include textEllipsisClamp(2);
                                    @include mobile {
                                        width: 165px;
                                        @include font(13px,19px);
                                        white-space: normal;
                                    }
                                }
                                .box_date {
                                    position: absolute;
                                    bottom: 20px;
                                    left: 20px;
                                    @include font(14px,20px);
                                    color: #666;
                                    @include mobile {
                                        bottom: 12px;
                                        left: 12px;
                                        @include font(12px,17px);
                                    }
                                }
                                .box_mark {
                                    position: absolute;
                                    bottom: 20px;
                                    right: 20px;
                                    @include mobile {
                                        bottom: 12px;
                                        right: 114px;
                                    }
                                    span {
                                        position: relative;
                                        display: inline-block;
                                        width: 66px;
                                        height: 28px;
                                        padding-right: 20px;
                                        background: url("/lg5-common/images/icons/icon-blog-37x17.png") no-repeat left center/38px 20px;
                                        font-size: 0;
                                        color: transparent;
                                        @include mobile {
                                            width: 37px;
                                            height: 16px;
                                            background-size: 21px 11px;
                                        }
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            width: 28px;
                                            height: 28px;
                                            background: url("/lg5-common/images/icons/btn-outlink-28.svg") no-repeat center/100%;
                                            @include mobile {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.type2 {
                    li {
                        @include mobile {
                            width: auto;
                        }
                    }
                }
            }
            &.newsty {
                @include mobile {
                    margin-left: 0;
                    padding-left: 4px;
                }
            }
            &.floorsty {
                li {
                    &:first-child {
                        .item {
                            border-top: 0;
                        }
                    }
                }
            }
        }
        // 고객지원 관련 배너
        .cs-banner {
            margin-top: 80px;
            @include mobile {
                margin-top: 28px;
            }
            .cs_tit {
                @include font(20px,28px);
                font-weight: 500;
                @include mobile {
                    @include font(16px,22px);
                    font-weight: 700;
                }
            }
            ul {
                display: flex;
                justify-content: space-between;
                margin-top: 24px;
                @include mobile {
                    display: block;
                    margin-top: 12px;
                }
                li {
                    position: relative;
                    width: 100%;
                    margin-left: 15px;
                    @include mobile {
                        display: block;
                        margin-left: 0;
                        margin-top: 8px;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        display: block;
                        padding: 16px 24px;
                        border-radius: 16px;
                        border: 1px solid #ddd;
                        background: no-repeat left 24px top 16px/24px;
                        &.phone {
                            background-image: url("/lg5-common/images/icons/icon-chatbot-60-customer.svg");
                        }
                        &.trip {
                            background-image: url("/lg5-common/images/icons/icon-csbanner-32-trip.svg");
                        }
                        &.visit {
                            background-image: url("/lg5-common/images/icons/icon-csbanner-32-visit.svg");
                        }
                        @include mobile {
                            padding: 16px;
                            border-radius: 8px;
                            background: no-repeat left 16px top 16px/32px;
                        }
                        .csb_tit {
                            display: block;
                            padding-left: 32px;
                            @include font(16px,24px);
                            font-weight: 500;
                            @include mobile {
                                @include font(13px,19px);
                                font-weight: 700;
                                padding-left: 40px;
                            }
                        }
                        .csb_cnt {
                            display: block;
                            @include font(12px,18px);
                            color: #666;
                            @include mobile {
                                @include font(12px,17px);
                                padding-left: 40px;
                            }
                        }
                    }
                }
            }
        }
        // 센터/매장 관련 배너
        .shopservice-banner {
            margin-top: 80px;
            @include mobile {
                margin-top: 4px;
            }
            ul {
                overflow: hidden;
                text-align: center;
                li {
                    position: relative;
                    display: inline-block;
                    width: 33%;
                    text-align: center;
                    @include mobile {
                        float: none;
                        width: 100%;
                        padding: 16px 0;
                        text-align: left;
                        border-top: 1px solid #ddd;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top:10px;
                        left: 0;
                        width: 1px;
                        height: 40px;
                        background-color: #ddd;
                        @include mobile {
                            content: none;
                        }
                    }
                    &:first-child {
                        &::before {
                            content: none;
                        }
                        @include mobile {
                            border-top: 0;
                        }
                    }
                    .service {
                        display: inline-block;
                        max-width: 264px;
                        padding-left: 84px;
                        background: no-repeat left center/60px;
                        text-align: left;
                        &.trip {background-image: url("/lg5-common/images/icons/ico-shopservice-60-trip.svg");}
                        &.advice {background-image: url("/lg5-common/images/icons/ico-shopservice-60-advice.svg");}
                        &.chat {background-image: url("/lg5-common/images/icons/ico-shopservice-60-chat.svg");}
                        @include mobile {
                            display: block;
                            max-width: 100%;
                            padding-left: 12px;
                            background: no-repeat right 8px center/40px;
                        }
                        .tit {
                            display: block;
                            @include font(16px,24px);
                            @include mobile {
                                @include font(14px,20px);
                            }
                        }
                        .cnt {
                            display: block;
                            margin-top: 4px;
                            @include font(12px,18px);
                            @include mobile {
                                padding-right: 80px;
                                @include font(12px,17px);
                            }
                        }
                    }
                }
            }
        }
        // 버튼 스타일
        .result-btn-area {
            display: flex;
            @include mobile {
                width: 100%;
            }
           .result-btn {
                border-radius: 20px;
                text-align: center;
                @include font(14px,40px);
                @include mobile {
                    border-radius: 8px;
                    @include font(13px,34px);
                }
                & + .result-btn {
                    margin-left: 8px;
                }
                &.fix {
                    display: inline-block;
                    width: 180px;
                    vertical-align: middle;
                    @include mobile {
                        width: 100%;
                    }
                }
                &.white {
                    border: 1px solid #666;
                    background-color: #fff;
                    color: #000;
                }
                &.black {
                    border: 1px solid #222;
                    background-color: #222;
                    color: #fff;
                }
                &.purple {
                    border: 1px solid #ea1917;
                    background-color: #ea1917;
                    color: #fff;
                }
            }
        }
        // 모바일 전체보기 숨김 처리
        &.event {
            @include mobile {
                .result-allview_area {
                    display: none !important;
                }
            }
        }

        // 동영상
        &.video-list-wrap {
            .list-sorting.insort {
                padding-bottom: 10px;
            }
            // .sort-area {
            //     padding-bottom: 24px;
            //     border-bottom: 1px solid #ddd;
            // }
            .video-list-area {
                & > div {
                    & + div {
                        margin-top: 80px;
                        padding-top: 24px;
                        border-top: 1px solid #ddd;
                        @include mobile {
                            margin-top: 32px;
                            padding-top: 10px;
                        }
                    }
                }
                strong {
                    @include font(20px, 29px);
                    font-weight: 700;
                }
                .ellarshow-area {
                    margin-top: 24px;
                    ul {
                        display: flex;
                        overflow-x: auto;
                        @include pc {
                            &::-webkit-scrollbar {
                                height: 16px;
                                background-color:transparent;
                            }
                            &::-webkit-scrollbar-thumb {
                                border-radius:7px;
                                background-color:rgba(0,0,0,1);
                                border-top:4px solid rgba(255,255,255,1);
                                border-bottom:4px solid rgba(255,255,255,1);
                            }
                            &::-webkit-scrollbar-track {
                                border-radius:7px;
                                background-color:rgba(0,0,0,0.2);
                                border-top:4px solid rgba(255,255,255,1);
                                border-bottom:4px solid rgba(255,255,255,1);
                            }
                        }
                        @include mobile {
                            margin: 0 -16px;
                            padding: 0 16px;
                        }
                        li {
                            flex: none;
                            overflow: hidden;
                            position: relative;
                            width: 268.5px;
                            a {
                                display: block;
                                position: relative;
                                z-index: 1;
                                height: 100%;
                            }
                            .ellar-info {
                                overflow: hidden;
                                position: relative;
                                height: 474px;
                                border-radius: 16px;
                                .img {
                                    display: block;
                                    img {
                                        width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .schedule-info {
                                    position: absolute;
                                    bottom: 80px;
                                    left: 0;
                                    width: 100%;
                                    z-index: 2;
                                    text-align: center;
                                    .s-date {
                                        display: block;
                                        margin-top: 12px;
                                        color: #fff;
                                        @include font(16px, 24px);
                                        font-weight: 500;
                                    }
                                    .s-time {
                                        display: block;
                                        margin-top: -3px;
                                        color: #fff;
                                        @include font(36px, 48px);
                                        font-weight: 700;
                                    }
                                }
                                .play-time {
                                    display: inline-block;
                                    position: absolute;
                                    bottom: 16px;
                                    left: 16px;
                                    z-index: 1;
                                    height: 24px;
                                    padding: 0 7px;
                                    background: rgba(0, 0, 0, 0.4);
                                    color: #fff;
                                    @include font(12px, 24px);
                                    border-radius: 4px;
                                }
                            }
                            .tit {
                                height: 84px;
                                padding: 16px 8px;
                                @include font(18px, 26px);
                                @include textEllipsisClamp(2);
                                font-weight: 500;
                            }
                            & + li {
                                margin-left: 24px;
                            }
                            .ico-ellashow {
                                display: inline-block;
                                width: 88px;
                                height: 88px;
                                background: #fff url(/lg5-common/images/icons/img_ellarshow.png) no-repeat 50% 50% / 64px 64px;
                                font-size: 0;
                                text-indent: -9999em;
                                border-radius: 44px 44px;
                            }
                            &.schedule {
                                .ellar-info {
                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        background: rgba(0, 0, 0, 0.4);
                                    }
                                    .play-time {
                                        background: #ea1917;
                                    }
                                }
                            }
                            &.last {
                                .ellar-info {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    background: #f6f6f6;
                                    border: 1px solid #ddd;
                                    p {
                                        margin-top: 16px;
                                        span {
                                            position: relative;
                                            padding-right: 16px;
                                            font-size: 16px;
                                            &::after {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                right: 0;
                                                width: 16px;
                                                height: 16px;
                                                background: url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat 50% 50%;
                                                transform: translateY(-50%);
                                            }
                                        }
                                    }
                                }
                                @include mobile {
                                    .ico-ellashow {
                                        width: 52px;
                                        height: 52px;
                                        background-size: 36px 36px;
                                        border-radius: 26px 26px;
                                    }
                                }
                            }
                        }
                    }
                }
                .archive-wrap {
                    .tit-area {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .sort-list {
                        position: relative;
                        z-index: 1;
                    }
                    .archive-list-wrap {
                        margin-top: 24px;
                        .archive-list-area {
                            position: relative;
                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                margin: -40px 0 0 -24px;
                                li {
                                    width: calc((100% / 4) - 24px);
                                    margin: 40px 0 0 24px;
                                    .mob-player {
                                        display: none;
                                    }
                                }
                            }
                            .thumbnail-area {
                                overflow: hidden;
                                position: relative;
                                width: 100%;
                                height: 151px;
                                border-radius: 16px;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    bottom: 10px;
                                    right: 10px;
                                    z-index: 1;
                                    width: 36px;
                                    height: 36px;
                                    background: url(/lg5-common/images/icons/ico_play_off_36.svg) no-repeat 0 0;
                                }
                                .play-time {
                                    position: absolute;
                                    bottom: 16px;
                                    left: 16px;
                                    z-index: 1;
                                    height: 24px;
                                    padding: 0 11px 0 10px;
                                    background: rgba(0, 0, 0, 0.4);
                                    color: #fff;
                                    @include font(12px, 24px);
                                    border-radius: 4px;
                                }
                            }
                            .txt-info {
                                padding: 16px 8px 0;
                                .tit {
                                    @include font(18px, 26px);
                                    @include textEllipsisClamp(2);
                                    font-weight: 500;
                                }
                                .add-info {
                                    margin-top: 4px;
                                    font-size: 0;
                                    span {
                                        display: inline-block;
                                        position: relative;
                                        color: #666;
                                        @include font(16px, 24px);
                                        & + span {
                                            margin-left: 8px;
                                            padding-left: 9px;
                                            &::before {
                                                content: '';
                                                position: absolute;
                                                top: 50%;
                                                left: 0;
                                                width: 1px;
                                                height: 12px;
                                                background: #ddd;
                                                transform: translateY(-50%);
                                            }
                                        }
                                    }
                                }
                                .btn-archive-detail {
                                    display: inline-block;
                                    margin-top: 4px;
                                    padding-right: 18px;
                                    background: url(/lg5-common/images/icons/ico_arrow_g_16.svg) no-repeat 100% calc(50% + 1px) / 16px 16px;
                                    color: #666;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                            li.active {
                                z-index: 7;
                                padding-bottom: 356px;
                                .thumbnail-area {
                                    border: 1px solid #707070;
                                    &::before {
                                        background-image: url(/lg5-common/images/icons/ico_play_on_36.svg);
                                    }
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        background: rgba(0, 0, 0, 0.5);
                                    }
                                }

                                .pc-player {
                                    display: block;
                                }
                            }
                            a {
                                display: block;
                            }
                            .img {
                                display: block;
                                position: relative;
                                height: 100%;
                                img {
                                    width: 100%;
                                    max-height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .pc-player {
                                display: none;
                                position: absolute;
                                bottom: 0;
                                height: 316px;
                                background: #333;
                                .btn-video-cls {
                                    position: absolute;
                                    top: 16px;
                                    right: 16px;
                                    z-index: 10;
                                    width: 32px;
                                    height: 32px;
                                    background: url(/lg5-common/images/icons/ico_pop_close_32.svg) no-repeat 0 0;
                                }
                                .inner {
                                    width: 560px;
                                    height: 316px;
                                    margin: 0 auto;
                                    .player-inner {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include mobile {
                // .sort-area {
                //     padding-bottom: 20px;
                // }
                .video-list-area {
                    strong {
                        @include font(16px, 23px);
                    }
                    .ellarshow-area {
                        margin-top: 10px;
                        ul {
                            padding-bottom: 8px;
                            li {
                                width: 132px;
                                .ellar-info {
                                    height: 233px;
                                    border-radius: 8px;
                                    .schedule-info {
                                        bottom: 38px;
                                        .s-date {
                                            margin-top: 4px;
                                            @include font(12px, 17px);
                                        }
                                        .s-time {
                                            margin-top: -1px;
                                            @include font(16px, 24px);
                                        }
                                    }
                                    .play-time {
                                        bottom: 10px;
                                        left: 10px;
                                        height: 21px;
                                        padding: 0 7px;
                                        background: rgba(0, 0, 0, 0.4);
                                        color: #fff;
                                        @include font(11px, 21px);
                                        border-radius: 3px;
                                    }
                                }
                                .tit {
                                    height: auto;
                                    padding: 8px 4px 0 4px;
                                    @include font(15px, 21px);
                                    @include textEllipsisClamp(2);
                                }
                                & + li {
                                    margin-left: 12px;
                                }
                                .ico-ellashow {
                                    width: 44px;
                                    height: 44px;
                                    background-size: 32px 32px;
                                    border-radius: 22px 22px;
                                }
                                &.last {
                                    .ellar-info {
                                        p {
                                            margin-top: 8px;
                                            span {
                                                padding-right: 14px;
                                                font-size: 12px;
                                                &::after {
                                                    width: 12px;
                                                    height: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .archive-wrap {
                        .archive-list-wrap {
                            margin-top: 10px;
                            .archive-list-area {
                                ul {
                                    margin: 0;
                                    flex-direction: column;
                                    li {
                                        width: 100%;
                                        margin: 0;
                                        .mob-player {
                                            position: absolute;
                                            top:0;
                                            left: 0;
                                            z-index: 10;
                                            width: 100%;
                                            height: 100%;

                                            iframe {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                        &.active {
                                            .mob-player {
                                                display: block;
                                            }
                                        }
                                        & + li {
                                            margin: 20px 0 0 0;
                                        }
                                    }
                                }
                                .thumbnail-area {
                                    height: calc(56.25vw - 32px);
                                    border-radius: 8px;
                                    &::before {
                                        width: 20px;
                                        height: 20px;
                                        background-size: 20px 20px;
                                    }
                                    .play-time {
                                        bottom: 10px;
                                        left: 10px;
                                        height: 21px;
                                        padding: 0 10px 0 9px;
                                        @include font(11px, 21px);
                                        border-radius: 3px;
                                    }
                                }
                                .txt-info {
                                    padding: 8px 4px 0;
                                    .tit {
                                        @include font(15px, 21px);
                                    }
                                    .add-info {
                                        margin-top: 2px;
                                        span {
                                            @include font(10px, 15px);
                                            & + span {
                                                margin-left: 4px;
                                                padding-left: 4px;
                                                &::before {
                                                    width: 1px;
                                                    height: 8px;
                                                }
                                            }
                                        }
                                    }
                                    .btn-archive-detail {
                                        margin-top: 2px;
                                        padding-right: 14px;
                                        background-position: 100% 50%;
                                        background-size: 12px 12px;
                                        color: #666;
                                        font-size: 12px;
                                        line-height: 15px;
                                    }
                                }
                                li.active {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .benefit-area {
        display: block;
        position: relative;
        font-size: 0;

        .btn_area {
            display: inline-block;
            width: 180px;
            vertical-align: middle;
        }

        @include mobile {
            display: block;

            .btn_area {
                width: 100%;
                margin-top: 16px;

                a {
                    display: block;
                    width: 100%;
                    height: 40px;
                    border-radius: 8px;
                    @include font(13px, 40px);
                }
            }
        }

        .benefit-info {
            display: inline-block;
            width: calc(100% - 180px);
            vertical-align: top;

            @include mobile {
                width: 100%;
            }

            div {
                display: inline-block;
                margin: 12px 16px 0 0;

                @include mobile {
                    display: block;
                    margin-right: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                & + div {
                    @include mobile {
                        margin-top: 8px;
                    }
                }
            }

            span {
                margin-right: 2px;
                color: #666;
                font-size: 14px;

                @include mobile {
                    display: inline-block;
                    width: 108px;
                    margin-right: 0;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
            strong {
                color: #000;
                font-size: 14px;
                font-weight: 500;

                &.price {
                    color: #ea1917;
                }

                @include mobile {
                    display: inline-block;
                    width: calc(100% - 108px);
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }
            }
        }
    }
}

//AI 정답형 검색영역
.result-ai {
    padding-bottom: 60px;
    @include mobile {
        padding: 20px 16px 30px 16px;
    }
    .ai_head {
        .head_stit {
            @include font(20px,28px);
            font-weight: 500;
            @include mobile {
                @include font(13px,22px);
            }
        }
        .head_btit {
            margin-top: 8px;
            @include font(36px,44px);
            font-weight: 700;
            @include mobile {
                margin-top: 3px;
                @include font(22px,22px);
            }
        }
    }
    .ai_body {
        position: relative;
        margin-top: 52px;
        padding: 40px 32px;
        background-color: #f4f4f4;
        border-radius: 16px;
        &::before {
            content: "";
            position: absolute;
            top: -12px;
            left: 0;
            width: 36px;
            height: 24px;
            background: url(/lg5-common/images/icons/icon-ai-mark.svg) no-repeat center/100%;
            @include mobile {
                top: -8px;
                width: 27px;
                height: 18px;
            }
        }
        @include mobile {
            margin-top: 24px;
            padding: 20px;
            border-radius: 8px;
        }
        .body_tit {
            @include textEllipsisClamp(2);
            @include font(24px,32px);
            font-weight: 700;
            @include mobile {
                @include font(16px,22px);
            }
        }
        .body_cnt {
            margin-top: 16px;
            @include font(20px,28px);
            @include mobile {
                margin-top: 12px;
                @include font(13px,19px);
            }
        }
    }
}

//NEW A필터
.nafilter_tabs {
    @media screen and (min-width: 1025px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width:1024px) {
        position: sticky;
        top: 94px;
        margin:0 -40px 0 -40px;
        background-color: #fff;
        z-index: 8;
        &.tabs-wrap {
            .tabs {
                display: block;
                padding: 20px 16px 20px 0;
                border: 0;
                border-bottom: 1px solid #ddd;
                text-align: left;
                overflow-x: auto;
                white-space: nowrap;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                > li {
                    padding: 0 16px;
                    border-radius: 20px;
                    background-color: #f4f4f4;
                    a {
                        @include font(16px,33px);
                        font-weight: 400;
                    }
                    &::before {
                        content: none;
                    }
                    &:first-child {
                        padding: 0 16px;
                        margin-left: 24px;
                    }
                    & + li {
                        margin-left: 16px;
                    }
                    &.on {
                        background-color: #000;
                        a {
                            color: #fff;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    @include mobile {
        position: sticky;
        top: 70px;
        z-index: 18;
        padding: 12px 0;
        background-color: #f8f8f8;
        &.tabs-wrap {
            .tabs {
                padding: 0 16px 0 0;
                overflow-x: auto;
                white-space: nowrap;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                > li {
                    padding: 7px 12px;
                    border: 1px solid #fff;
                    border-radius: 16.5px;
                    background-color: #fff;
                    a {
                        @include font(13px,19px);
                        font-weight: 400;
                    }
                    &::before {
                        content: none;
                    }
                    &:first-child {
                        margin-left: 16px;
                        padding: 7px 12px;
                    }
                    & + li {
                        margin-left: 8px;
                    }
                    &.on {
                        border-color: #222;
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
.nafilter_anchor {
    @media screen and (min-width: 1025px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width:1024px) {
        display: none;
    }
    @include mobile {
        background-color: #fff;

        .anchor_tabs {
            &::before {
                content: none;
            }
            .tabs {
                display: block;
                padding: 0 16px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                li {
                    width: auto; // BTOCSITE-33230 스마트필터 / 큐레이션 간격 수정
                    margin: 0;
                    margin: 0 36px 0 0; // BTOCSITE-33230 스마트필터 / 큐레이션 간격 수정
                    text-align: center;
                    &:last-child { // BTOCSITE-33230 스마트필터 / 큐레이션 간격 수정
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        padding: 10px 0 7px;
                        @include font(13px,19px);
                        font-weight: 400;
                        color: #666;
                    }
                    &.on {
                        a {
                            font-weight: 700;
                            color: #000;
                            &:after {
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 2px;
                                border-radius: 0;
                                background-color: #000;
                                content: '';
                            }
                        }
                    }
                }
            }
        }
        .anchor_list {
            position: relative;
            overflow: hidden;
            background-color: #f8f8f8;
            
            .swiper {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                background-color: #f8f8f8;
                &.multi {
                    .swiper-wrapper {
                        .swiper-slide {
                            min-height: 105px;
                        }
                    }
                }
                .swiper-wrapper {
                    @include mobile {
                        transform-style: initial;
                    }
                    .swiper-slide {
                        background-color: #f8f8f8;
                        .anchor-txt2  {  // BTOCSITE-19776 필터 내 용어사전 추가 추가 css
                            .slide-btn {
                                margin-bottom:16px;
                                p {
                                    display:inline-block; 
                                    font-size:13px;   
                                    position: relative;    
                                    line-height: 18px; 
                                    font-weight: 500; 
                                    &::before {
                                        content:"";
                                        display:inline-block;
                                        background-color:#e81953;
                                        width:100%;
                                        height:10px;
                                        position:absolute;
                                        bottom:0;
                                        left:0;
                                        opacity:0.2;
                                    }
                                }
                                .tooltip-wrap {
                                    margin:1.6px 0 0 -2px;
                                    height:auto;
                                }
                                span {
                                    width:18px;
                                    height:18px;
                                }
                            }
                        } // BTOCSITE-19776 필터 내 용어사전 추가 
                        ul {
                            overflow: hidden;
                            li {
                                position: relative;
                                float: left;
                                width: 50%;
                                border: 1px solid #ddd;
                                input {
                                    position: absolute;
                                    & + label {
                                        display: block;
                                        padding: 8px 0;
                                        background-color: #fff;
                                        @include font(13px,19px);
                                        text-align: center;
                                    }
                                    &:checked {
                                        & + label {
                                            background-color: #000;
                                            color: #fff;
                                            font-weight: 500;
                                        }
                                    }
                                    &:disabled {
                                        & + label {
                                            background-color: #f4f4f4;
                                            color: #aaa;
                                        }
                                    }
                                }
                                &:nth-child(2n) {
                                    margin-left: -1px;
                                }
                                &:nth-child(3n) {
                                    margin-top: -1px;
                                }
                                &:nth-child(4n) {
                                    margin: -1px 0 0 -1px;
                                }
                            }
                        }
                        padding: 16px 16px 32px;
                        margin: 0;
                    }
                }
                .swiper-pagination {
                    .swiper-pagination-bullet {
                        width: 6px;
                        height: 6px;
                        margin: 0 4px;
                        border-radius: 50%;
                        background-color: rgba(0,0,0,0.2);
                        opacity: 1;
                        &.swiper-pagination-bullet-active {
                            width: 18px;
                            border-radius: 3px;
                            background-color: rgba(0,0,0,0.8);
                        }
                    }
                }
            }
            //BTOCSITE-19776 필터 내 용어사전 용어사전(L)
        }
    }
} 

// 검색 > 내일배송, 임직원할인제품
.check-filter-wrap {
    display: none;

    @include mobile {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 10;
        padding: 0 16px;
        background-color: #fff;
    
        [class*=_checkbox] {
            .chk-wrap {
                input {
                    & + label {
                        @include font(12px,24px);
                        &::after {
                            top: 0;
                            background-image: url(/lg5-common/images/icons/btn-checkbox-off-24-black.svg);
                        }
                    }
                    &:checked + label {
                        @include font(12px,24px);
                        font-weight: 700;
                        &::after {
                            top: 0;
                            background-image: url(/lg5-common/images/icons/btn-checkbox-on-24-black.svg);
                        }
                    }
                    &:disabled + label {
                        @include font(12px,24px);
                        color: #666;
                        &::after {
                            top: 0;
                            background-image: url(/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg);
                        }
                    }
                }
                label {
                    padding-left: 26px;
                    padding-right: 28px;
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 24px;
                        height: 24px;
                        background: url(/lg5-common/images/icons/ico_truck_32.svg) no-repeat 50% 50%;
                        background-size: 24px;
                    }
                    &::after {
                        left: auto;
                        right: 0;
                        background-size: 20px;
                    }
                }
            }
        }
        .tomorrow_checkbox {
            margin-right: 20px;
        }
    
        .multi_product_checkbox, //BTOCSITE-48682 다품목할인 추가
        .executive_checkbox {
            .chk-wrap {
                input {
                    & + label {
                        padding-left: 0;
    
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        .multi_product_checkbox { //BTOCSITE-48682 다품목할인 추가
            margin-right: 20px;
        }

        
        .chk-wrap {
            padding: 12px 0;
        }

        &.active + .nafilter_anchor {
            position: relative;
            z-index: 10;
            margin-top: -12px;
        }
    }
}

.hidden { //BTOCSITE-19776 필터 내 용어사전 배경스크롤 막기
    height: 100vh;
    width:100%;
    overflow:hidden !important;
    touch-action: none;
} //BTOCSITE-19776 필터 내 용어사전
// BTOCSITE-19776용어사전(L)
#glossaryPopup03 {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index:9999;
    max-width: 100%;
    &.active {
        display:block !important;
        overflow-y: auto;
    }
    @include mobile {
        height:100%;
    }
    .pop-header {
        border:0;
        width:912px;
        margin:0 auto;
        padding:224px 0 40px;
        @include mobile {
            padding:46px 0 30px 20px;
            width:100%;
        }
        span {
            font-size:24px;
            font-weight:500 !important;
            @include mobile {
                font-size:18px;
                font-weight:500;
            }
        }
    }
    .glossary-cont {
        width:912px;
        padding:0;
        margin:0 auto;
        max-height:620px;
        overflow-y: visible;
        scrollbar-width:none;
        -ms-overflow-style:none;
        &::-webkit-scrollbar {
            display:none;
        }
        @include mobile {
            padding:0 20px;
            overflow:visible;
            width:100%;
        }
        .banner {
            overflow:hidden;
            margin-bottom:16px;
            border-radius:16px;
            @include mobile {
                border-radius:8px;
            }
            img {
                width:100%;
                @include mobile {
                    max-width:100%;
                }
            }
        }
        .add-txt {
            font-size:16px !important;
            @include mobile {
                font-size:13px !important;
            }
        }
        .sub-tit {
            margin-bottom:15px;
            font-weight:$font-weight-bold;
            font-size:20px;
            line-height:1.3;
            margin-top:22px !important;
            @include mobile {
                font-size:15px;
            }
            ~ .sub-tit {
                margin-top:40px;
                padding-top:40px;
                border-top:1px solid #ddd;
                @include mobile {
                    padding:0;
                    border:0;
                }
            }
        }
        .list-item {
            display:flex;
            flex-flow:row wrap;
            justify-content:space-between;
            margin:-8px -12px;
            border:0;
            @include mobile {
                margin:0;
            }
            li {
                display:inline-block;
                width:50%;
                padding:8px 12px;
                border:0;
                @include mobile {
                    width:100%;
                    padding:10px 0;
                    border-bottom:1px solid #ddd;
                }
                .item-box {
                    position:relative;
                    padding:6px 6px 6px 143px;
                    min-height:127px;
                    @include mobile {
                        padding:4px 9px 0 111px;
                        min-height:103px;
                    }
                    .thum {
                        position:absolute;
                        top:0;
                        left:0;
                        width:127px;
                        height:127px;
                        border-radius:16px;
                        overflow:hidden;
                        @include mobile {
                            width:103px;
                            height:103px;
                            border-radius:8px;
                        }
                        > img {
                            max-width:100%;
                        }
                    }
                    .title {
                        display:inline-block;
                        font-weight:$font-weight-bold;
                        font-size:16px;
                        line-height:1.64;
                        @include mobile {
                            font-size:12px;
                        }
                    }
                    .description {
                        margin:8px 0 6px;
                        color:$color-gray-1;
                        font-size:13px;
                        line-height:1.5;
                        @include mobile {
                            margin:7.5px 0 2.5px;
                            color:$color-default;
                            font-size:10.5px;
                        }
                    }
                }
            }
        }
        [class^="add-"] {
            font-size:18px;
            line-height:1.4;
            @include mobile {
                font-size:13px;
            }
        }
        .add-tit {
            display:block;

        }
        .add-txt {
            margin-top:4px;
            + .sub-tit {
                margin-top:42px;
            }
        }
    }
    .no-footer {
        &::after {
            padding-bottom:100px;
            @include mobile {
                padding-bottom:48px;
            }
        }
    }
    .btn-close {
        top:114px;
        right:272px;
        width:36px;
        height:36px;
        &::before {
            background-size:36px;
            width:36px;
            height:36px;
            @include mobile {
                background-size:24px;
                width:24px;
                height:24px;
            }
        }
        @include mobile {
            position:absolute;
            top:11px;
            right:16px;
            width:24px;
            height:24px;
        }
    }
}
.nafilter_choice {
    @media screen and (min-width: 1025px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width:1024px) {
        position: sticky;
        top: 168px;
        margin:0 -40px 0 -40px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        z-index: 8;
        &.scroll_sticky {
            box-shadow: 0 4px 12px 0 rgba(0,0,0,0.14);
        }
        .choice_base {
            float: left;
            padding: 0 24px;
            @include font(16px,72px);
            color: #666;
        }
        .choice_item {
            overflow: hidden;
            .item_reset {
                float: left;
                position: relative;
                width: 113px;
                height: 72px;
                padding-left: 24px;
                &::before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 5px);
                    right: 0;
                    width: 1px;
                    height: 10px;
                    background-color: #ddd;
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: -12px;
                    top: 0;
                    width: 12px;
                    height: 72px;
                    background: linear-gradient(to left, rgba(255,255,255,0), #fff);
                }
                a {
                    display: block;
                    width: 72px;
                    height: 100%;
                    padding-left: 28px;
                    @include font(16px,72px);
                    font-weight: 500;
                    background: url("/lg5-common/images/icons/btn-reset-black.svg") no-repeat left center/24px;
                }
            }
            .item_area {
                padding: 24px 16px;
                overflow-x: auto;
                white-space: nowrap;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                li {
                    overflow: hidden;
                    display: inline-block;
                    & + li {
                        margin-left: 24px;
                    }
                    > span {
                        display: inline-block;
                        vertical-align: middle;
                        @include font(16px,24px);
                    }
                    a {
                        display: inline-block;
                        margin-left: 4px;
                        vertical-align: middle;
                        width: 24px;
                        height: 24px;
                        background: url('/lg5-common/images/icons/btn-clear-32.svg') no-repeat center/100%;
                        font-size: 0;
                        color: transparent;
                    }
                }
            }
        }
        .btn-filter {
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            float: right;
            margin:19.5px 16px 19.5px 0;
            padding: 0 0 0 4px;
            background-color: #fff;
            &::after {
                content: none;
            }
            &::before {
                content: "" !important;
                position: absolute !important;
                left: -12px !important;
                top: -10px !important;
                width: 12px !important;
                height: 72px !important;
                background: linear-gradient(to right, rgba(255,255,255,0), #fff) !important;
            }
            .ico-filter {
                width: 33px;
                height: 33px;
                border-radius: 8px;
                background:#222 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
                em.text {
                    display: none;
                }
            }
        }
    }
    @include mobile {
        position: sticky;
        top: 129px;
        z-index: 8;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #d8d8d8;
        background-color: #f8f8f8;
        .choice_base {
            float: left;
            padding: 0 16px;
            @include font(13px,54px);
            color: #666;
            z-index: 9;
        }
        .choice_item {
            overflow: hidden;
            .item_reset {
                float: left;
                position: relative;
                width: 49px;
                height: 54px;
                padding-left: 16px;
                &::before {
                    content: "";
                    position: absolute;
                    top: calc(50% - 7px);
                    right: 0;
                    width: 1px;
                    height: 14px;
                    background-color: #d8d8d8;
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: -12px;
                    top: 0;
                    width: 12px;
                    height: 54px;
                    background: linear-gradient(to left, rgba(248,248,248,0), #f8f8f8);
                }
                a {
                    display: block;
                    width: 24px;
                    height: 100%;
                    background: url("/lg5-common/images/icons/btn-afilter-reset.svg") no-repeat center/24px;
                    font-size: 0;
                    color: transparent;
                }
            }
            .item_area {
                padding: 15px 12px;
                overflow-x: auto;
                white-space: nowrap;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                li {
                    display: inline-block;
                    @include font(13px,24px);
                    & + li {
                        margin-left: 8px;
                    }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    a {
                        display: inline-block;
                        margin-left: 4px;
                        width: 16px;
                        height: 16px;
                        background: url('/lg5-common/images/icons/btn-clear-32.svg') no-repeat center/100%;
                        font-size: 0;
                        color: transparent;
                        vertical-align: middle;
                    }
                }
            }
        }
        .btn-filter {
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            float: right;
            margin:10px 16px 10px 0;
            padding: 0 0 0 4px;
            background-color: #f8f8f8;
            &::after {
                content: none;
            }
            &::before {
                content: "" !important;
                position: absolute !important;
                left: -12px !important;
                top: -10px !important;
                width: 12px !important;
                height: 54px !important;
                background: linear-gradient(to right, rgba(248,248,248,0), #f8f8f8) !important;
            }
            .ico-filter {
                width: 33px;
                height: 33px;
                border-radius: 8px;
                background:#222 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
                em.text {
                    display: none;
                }
            }
        }
    }
}

.input-keyword-wrap.scroll ~ .cont-wrap.filter_fixed {
    @include mobile {
        .nafilter_choice {
            &.scroll_sticky {
                box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
            }
        }
    }
}


//NEW B필터
.anchor_choice {
    @media screen and (min-width: 768px) and (max-width:1024px) {
        display: none;
    }
    @include mobile {
        display: none;
    }
    position: absolute;
    width: 210px;
    background-color: #fff;
    z-index: 22;
    .anchor_area {
        border-top: 1px solid #000;
        border-bottom: 1px solid #ddd;
        padding: 16px 0 8px 0;
        .tabs-wrap {
            .tabs {
                overflow: hidden;
                display: block;
                white-space: normal;
                text-align: left;
                > li {
                    margin:0 8px 8px 0;
                    padding: 0;
                    a {
                        padding: 0 8px;
                        border-radius: 12px;
                        background-color: #f4f4f4;
                        @include font(12px,24px);
                        font-weight: 400;
                    }
                    &.on {
                        a {
                            background-color: #000;
                            font-weight: 700;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .choice_area {
        border-bottom: 1px solid #ddd;
        .choice_head {
            overflow: hidden;
            padding-top: 16px;
            .head_tit {
                float: left;
                @include font(14px,20px);
                font-weight: 700;
            }
            .head_reset {
                float: right;
                padding-left: 20px;
                background: url("/lg5-common/images/icons/btn-reset-black.svg") no-repeat left center/16px;
                @include font(12px,20px);
            }
        }
        .choice_item {
            padding: 21px 0 8px 0;
            li {
                display: inline-block;
                overflow: hidden;
                margin:0 16px 10px 0;
                .item_txt {
                    float: left;
                    @include font(12px,18px);
                }
                .item_del {
                    float: left;
                    width: 18px;
                    height: 18px;
                    margin-left: 4px;
                    background: url("/lg5-common/images/icons/btn-clear-32.svg") no-repeat center/16px;
                    font-size: 0;
                    color: transparent;
                }
            }
        }
    }
}

//스티커검색 부가항목
.contents.search.re22 {
    .cont-wrap {
        .search-result-wrap {
            .result-area {
                .list-sorting {
                    .search-input-layer {
                        top: 57px;
                    }
                }
            }
        }
    }
}

//코치마크 search_coachmark.png
.popup-wrap.coachmark {
    width: auto;
    background: none;
    text-align: center;
    &:focus {
        outline: none;
    }
    .pop-conts {
        padding: 36px 0 0 0;
        overflow: hidden;
        .coachmark_txt {
            @include font(16px,22px);
            color: #fff;
            font-weight: 400;
            em {
                font-weight: 700;
            }
        }
        img {
            width: 340px;
        }
    }
    .btn-close {
        position: fixed;
        top: 0;
        right: 0;
        bottom: initial;
        left: initial;
        &::before {
            background: url("/lg5-common/images/icons/icon_close_coachmark.svg") no-repeat center/24px;
        }
    }
}

.morelist-layer {
    .inner {
        .shared-model-wrap {
            .morelist_tit {
                margin-bottom: 40px;
                font-size: 0;
        
                .prod_name {
                    margin-right: 8px;
                    @include font(24px, 32px);
                    font-weight: 500;
                }

                .list_count {
                    display: inline-block !important;
                    margin-left: 0;
                    color: #000;
                    @include font(24px, 32px);
                    em {
                        color: #ea1917;
                        font-weight: 400;
                        @include font(24px, 32px);
                    }
                }

                @include mobile {
                    margin-bottom: 30px;
                    padding: 0 20px;
                    line-height: 1;

                    .prod_name {
                        margin-right: 6px;
                        @include font(18px, 24px);
                        font-weight: 500;
                    }
    
                    .list_count {
                        @include font(18px, 24px);
                        em {
                            @include font(18px, 24px);
                        }
                    }
                }
            }

            .model-info-wrap {
                padding: 32px 32px;
                background: #fff;
                border-radius: 16px;
                box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);

                @include mobile {
                    padding: 0 16px;
                    border-radius: 0;
                    box-shadow: none;
                }
            }
        
            .tit-wrap {
                margin-bottom: 20px;
                @include pc {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 28px;
                }
                & + .count {
                    margin-top: 0;
                }
            }
            .input-wrap {
                flex: 1;
                width: 100%;
                padding-right: 36px;
                height: 40px;
                @include pc {
                    height: 48px;
                    margin: 0 -8px;
                    padding-right: 48px;
                }
                input[type=text] {
                    padding: 11px 32px;
                    text-transform:uppercase;
                    background: #f4f5f7;
                    border-radius: 24px;
                    border: none;
                    font-size: 18px;
                    @include mobile {
                        height: 40px;
                        padding: 8px 20px;
                        font-size: 15px;
                        line-height: 22px;
                    }
                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &::-ms-input-placeholder {
                        color: #666;
                        font-size: 18px;
                        line-height: 26px;
                        @include mobile {
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }
                .btn-delete {
                    right: 50px;
                    @include pc {
                        width: 24px;
                        height: 24px;
                        right: 68px;
                    }
                }
            }
            .btn-search {
                width: 36px;
                background-image: url(/lg5-common/images/icons/icon-search-32.svg);
                background-size: 24px auto;
                @include mobile {
                    height: 40px;
                    background-position: 100% 50%;
                }
                @include pc {
                    background-size: 32px auto;
                    background-position: 100% 50%;
                }
            }
            .model-information-wrap {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 508px;
                padding: 24px 32px 24px 0;
                border: 1px solid #ddd;
                border-radius: 8px;

                @include mobile {
                    max-height: calc(100vh - 208px);
                    padding: 12px 20px 12px 0;
                }
            }
            .model-information-list {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex-basis: 50%;
                    padding: 8px 0 8px 32px;
                    @include font(14px, 20px);

                    @include mobile {
                        flex-basis: 100%;
                        padding: 4px 0 4px 20px;
                        @include font(13px, 19px);
                    }
                }
            }

            .no-data {
                background: none;
                padding: 80px 0 80px 32px;
                @include font(14px, 20px);
                @include mobile {
                    @include font(13px, 19px);
                    padding: 50px 0 50px 20px;
                }
            }
        }
    }
    
}

//사용 가능한 모델
#layerSharedModel {
    &.morelist-layer .inner {
        padding: 180px 0 0 0;

        @include mobile {
            padding: 54px 0 40px;
        }
    }
}

// 스펙 용어사전
.search.re22 {
    .btn-dict {
        p {
            display: inline-block;
            position: relative;
            margin-bottom: 16px;
            @include font(13px, 18px);
            font-weight: 500;
            &::before {
                content: "";
                display: inline-block;
                background-color: #e81953;
                width: 100%;
                height: 10px;
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0.2;
            }
        }
        .tooltip-wrap {
            margin: 1.6px 0 0 4px;
            @include mobile {
                margin: 1.6px 0 0 2px;
            }
        }
        span {
            width: 18px;
            height: 18px;
        }
    }
}

// 용어사전 팝업
.dict-pop {
    background-clip: initial!important;
    &.small {
        display: none;
        max-width: none;
        min-height: auto!important;;
        max-height: 692px;
        width: 678px;

        @include mobile {
            width: 328px;
            min-height: auto!important;
            max-height: 559px;
            padding: 0;
        }

        .pop-header {
            padding: 32px 0 16px;
            .tit {
                display: inline-block;
                width: 100%;
                font-weight: bold;
                color:#000;

                span {
                    font-size: 22px;

                    @include mobile {
                        font-size: 16px;
                    }
                }
            }
        }

        .pop-conts {
            position: relative;
            padding:40px 40px 64px;
            overflow-x: hidden;
            img {
                max-width: 100%;
            }
            @include mobile {
                max-height: 482px;
                padding: 30px 16px 30px;
            }
            .media-wrap {
                .visual-area {
                    @include mobile {
                        a {
                            display: block;
                        }
                    }
                }
                .ui_carousel_slider {
                    position: relative;

                    .ui_carousel_slide {
                        width: 100%;
                        .visual-wrap {
                            overflow: hidden;
                            width: 100%;
                            height: 337px;

                            @include mobile {
                                height: 166.8px;
                            }
                            .visual-area {
                                height: 100%;
                                a {
                                    display: block;
                                    height: 100%;
                                }
                                
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .slide-controls {
                        position: absolute;
                        top:auto;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 20px;
                        width: 120px;
                        height: 36px;
                        padding: 0 22px;
                        background: rgba(0,0,0,.25);
                        border-radius: 20px;
                        text-align: center;
            
                        @include mobile {
                            width: 73px;
                            height: 20px;
                            bottom: 8px;
                        }
            
                        .btn-arrow {
                            position: absolute;
                            top: 52%;
                            transform: translateY(-50%);
                            width: 20px;
                            height: 20px;
            
                            @include mobile {
                                width: auto;
                                height: auto;
                            }
            
                            &:before {
                                width: 12px;
                                height: 12px;
                                background: url(/lg5-common/images/icons/btn-arr-16-white.svg) no-repeat 50% 50%/100% auto;
            
                                @include mobile {
                                    width: 7px;
                                    height: 7px;
                                }
                            }
            
                            &.prev {
                                left: 20px;
            
                                @include mobile {
                                    left: 7px;
                                }
            
                                &:before {
                                    transform: rotate(180deg);
                                }
                            }
            
                            &.next {
                                right:20px;
            
                                @include mobile {
                                    right: 7px;
                                }
                            }
                        }
            
                        .slide-number {
                            height: 36px;
                            line-height: 36px;
                            color: rgba(255,255,255,.7);
            
                            @include mobile {
                                height: 14px;
                                line-height:14px;
                            }
            
                            .current {
                                color: #fff;
                                font-size: 16px;
            
                                @include mobile {
                                    font-size: 10px;
                                }
            
                                &:after {
                                    display: inline-block;
                                    width: 1px;
                                    height: 16px;
                                    margin: 0 8px;
                                    background-color: #fff;
                                    vertical-align: -2px;
                                    content: '';
                                    transform: rotate(25deg);
            
                                    @include mobile {
                                        margin: 0 3px;
                                        height: 8px;
                                        vertical-align: -1px;
                                    }
                                }
                            }
            
                            span {
                                font-size: 16px;
            
                                @include mobile {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
                .video-wrap{
                    position: relative;
                    width: 100%;
                    height: 337px;
                    overflow: hidden;

                    @include mobile {
                        width: 100%;
                        height: 56.3545%;
                    }

                    .mp4-wrap {
                        width: 100%;
                        height: 100%;
                    }
                    
                    .pop-video {
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                    }
    
                    .visual-area {
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .add-tit {
                display: block;
                @include font(16px, 24px);
                font-weight: 700;
            }
            .banner + .add-tit {
                margin-top: 16px;
            }
            .add-txt {
                margin-top: 32px;
                font-size: 14px;
                line-height: 1.71;
                &:first-child {
                    margin-top: 0;
                }
            }
            .add-tit + .add-txt {
                margin-top: 4px;
            }
            .sub-tit {
                @include font(18px, 24px);
            }
            .add-tit + .sub-tit,
            .add-txt + .sub-tit {
                margin-top: 32px;
            }
            .list-item {
                li {
                    margin-top: 16px;
                    .item-box {
                        position: relative;
                        min-height: 104px;
                        padding: 4px 0 0 120px;
                        .thum {
                            position: absolute;
                            top: 0;
                            left :0;
                            width: 104px;
                            height: 104px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .title {
                            @include font(16px, 24px);
                        }
                        .description {
                            margin-top: 4px;
                            @include font(14px, 20px);
                        }
                    }
                }
            }
            @include mobile {
                .add-tit {
                    @include font(13px, 19px);
                }
                .banner + .add-tit {
                    margin-top: 16px;
                }
                .add-txt {
                    margin-top: 16px;
                    font-size: 13px;
                    line-height: 1.46;
                }
                .add-tit + .add-txt {
                    margin-top: 4px;
                }
                .sub-tit {
                    @include font(15px, 19px);
                }
                .add-tit + .sub-tit,
                .add-txt + .sub-tit {
                    margin-top: 32px;
                }
                .list-item {
                    li {
                        margin-top: 0;
                        padding: 12px 0;
                        border-top: 1px solid #eee;
                        &:first-child {
                            border-top: 0;
                        }
                        .item-box {
                            min-height: 100px;
                            padding: 0px 0 0 108px;
                            .thum {
                                width: 100px;
                                height: 100px;
                            }
                            .title {
                                @include font(13px, 19px);
                            }
                            .description {
                                margin-top: 8px;
                                @include font(13px, 19px);
                            }
                        }
                    }
                }
            }
        }
        .btn-close {
            top: 38px;
            @include mobile {
                top:35px;
            }
        }
        &.scroll {
            .pop-conts {
                padding-right: 23px;
                @include mobile {
                    padding-right: 16px;
                }
            }
        }
    }
}